import { Store } from 'redux';
import { BrowserHistory } from 'history';

import { RootState } from './reducers';

interface Globals {
  store: Store<RootState> | null;
  history: BrowserHistory | null;
}

export const globals: Globals = {
  store: null,
  history: null,
};
