import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PayloadModel, RequestStatusModel } from '../../models/global';
import { BuildingModel } from '../../models';
import Api from '../../services/api';

export interface InitialState {
  currentClientId: number;
  clientData: any;
  fullCurrentProject: any | null;
  clientProjectLoading: RequestStatusModel;
  currentBuilding: BuildingModel | null;
}

const initialState: InitialState = {
  currentClientId: +(sessionStorage.getItem('client_id') || 0),
  clientData: {
    clientProjects: [],
    clients: [],
    clientProjectsLoading: false,
    selected_client_id: null,
  },
  fullCurrentProject: null,
  clientProjectLoading: Api.initialStatus,
  currentBuilding: null,
};

const clientsProjectsReducer = createSlice({
  name: 'kpiData',
  initialState,
  reducers: {
    setClient: (state, { payload }: PayloadAction<number>) => {
      sessionStorage.setItem('client_id', `${payload}`);
      state.currentClientId = payload;
    },
    clearClient: state => {
      sessionStorage.removeItem('client_id');

      state.currentClientId = 0;
      state.fullCurrentProject = null;
      state.currentBuilding = null;
      state.clientData = {
        clientProjects: [],
        clients: [],
        clientProjectsLoading: false,
        selected_client_id: null,
      };
    },
    getClientProjects: (state, { payload }: PayloadModel<{ clientId: number }>) => {
      state.clientProjectLoading = Api.requestStatus;
    },
    getClientProjectsSuccess: (state, { payload }: PayloadAction<any>) => {
      // state.clientProjects = payload;
      state.clientProjectLoading = Api.successStatus;
      console.log('payloadSelecterClient', payload);
      state.clientData = {
        clientProjects: payload.projects,
        clients: payload.clients,
        clientProjectsLoading: false,
        selected_client_id: payload.selected_client_id ? Number(payload.selected_client_id) : null,
      };
    },
    getProject: (state, { payload }: PayloadModel<{ id: number }>) => {
      state.clientProjectLoading = Api.requestStatus;
      state.fullCurrentProject = null;
    },
    getProjectSuccess: (state, { payload }: PayloadAction<any>) => {
      // state.clientProjects = payload;
      state.clientProjectLoading = Api.successStatus;
      state.fullCurrentProject = payload.data;
    },
    getClientFromProject: (state, { payload }: PayloadModel<{ code: string }>) => {
      state.clientProjectLoading = Api.requestStatus;
    },
    getClientFromProjectSuccess: (state, { payload }: PayloadAction<{ client_id: number }>) => {
      sessionStorage.setItem('client_id', `${payload.client_id}`);
      state.currentClientId = payload.client_id;
      state.clientProjectLoading = Api.successStatus;
    },
    setCurrentBuilding: (state, { payload }: PayloadAction<BuildingModel>) => {
      // console.log('payload', payload)
      state.currentBuilding = payload;
    },
  },
});

const {
  setClient,
  clearClient,
  getClientProjects,
  getClientProjectsSuccess,
  getProject,
  getProjectSuccess,
  getClientFromProject,
  getClientFromProjectSuccess,
  setCurrentBuilding,
} = clientsProjectsReducer.actions;

export {
  setClient,
  clearClient,
  getClientProjects,
  getClientProjectsSuccess,
  getProject,
  getProjectSuccess,
  getClientFromProject,
  getClientFromProjectSuccess,
  setCurrentBuilding,
};

export default clientsProjectsReducer.reducer;
