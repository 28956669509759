import { useRef, useCallback, useEffect } from 'react';

import { AUTH_SERVICE_URL } from '../../constants/api';

import { PostEvent, PostMessage, PostTypes } from "./types";

export const usePostMessage = () => {

  const subscribes = useRef<({ handler: (params: CustomEvent) => void, type: PostTypes })[]>([]);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const sendMessage = useCallback((message: PostMessage) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const messageToString = JSON.stringify(message);
      iframeRef.current.contentWindow.postMessage(messageToString, AUTH_SERVICE_URL);
    } else {
      throw new Error('Cannot find iframeRef contentWindow')
    }
  }, []);

  const onMessage = useCallback((message: MessageEvent) => {
    if (typeof message.data !== 'string' || !message.data) return;

    try {
      const { type, data }: PostMessage = JSON.parse(message.data)

      if (!type || !PostTypes[type]) throw Error('Type post message is undefined!');

      const customEvent = new CustomEvent(type, {
        detail: data
      });

      document.dispatchEvent(customEvent);



    } catch (err) {
      console.warn(err);
      return false
    }
  }, [])

  const subscribe = useCallback(({ type, callback }: PostEvent) => {
    const handler = ({ detail }: CustomEvent) => callback(detail);
    const id = subscribes.current.push({ handler, type }) - 1;
    document.addEventListener(type, subscribes.current[id].handler as EventListener);

    return id;
  }, []);

  useEffect(() => {
    window.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [onMessage]);

  useEffect(() => {
    return () => {
      subscribes.current.forEach(({ type, handler }) => {
        document.removeEventListener(type, handler as EventListener);
      })
    }
  }, [])

  return {
    iframeRef,
    subscribe,
    sendMessage
  }
}