import * as React from 'react';

import './CheckBoxStyles.scss';

interface IProps {
  size?: string;
  color?: string;
  error?: string;
  checked: boolean;
  disabled?: boolean;
  children?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  noText?: boolean;
  className?: string;
  id?: string;
}

const CheckBox = (props: IProps) => {
  const { checked, children, onChange, error, size, disabled = false, noText, className, id } = props;
  let inputRef: React.MutableRefObject<any>;
  inputRef = React.useRef(null);

  return (
    <div className={'checkbox'}>
      <div className={`check-box-body ${noText ? '' : 'check-box-container'} ${className || ''}`}>
        <div className={`check-box-wrap`}>
          <input
            tabIndex={disabled ? -1 : 0}
            className={`${size ? size : ''} ${error ? error : ''}`}
            ref={inputRef}
            checked={checked}
            disabled={disabled}
            type="checkbox"
            // onKeyPress={onKeyPress}
            onChange={onChange}
            aria-label={'check-box'}
            id={id}
          />
          {children && <label htmlFor={id}>{noText ? null : children}</label>}
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
