import axios from 'axios';
import { API_URI } from '../constants';
import { LOGOUT_EVENT } from '../constants/events';
import { Auth } from '../components/pages';
import React from 'react';
import { KeyValueModel } from '../models';

export default class Api {
  static methods = {
    GET: 'get',
    POST: 'post',
    PATCH: 'patch',
    PUT: 'put',
    DELETE: 'delete',
  };

  static get initialStatus() {
    return {
      loading: false,
      loaded: false,
      fail: false,
    };
  }

  static get requestStatus() {
    return {
      loading: true,
      loaded: false,
      fail: false,
    };
  }

  static get successStatus() {
    return {
      loading: false,
      loaded: true,
      fail: false,
    };
  }

  static get failStatus() {
    return {
      loading: false,
      loaded: false,
      fail: true,
    };
  }

  static composeRouteUrl(route: string) {
    if (route.startsWith('http')) {
      return route;
    }
    return `${API_URI}${route}`;
  }

  static get(route: string, params?: object) {
    return Api.request(route, params, undefined, Api.methods.GET);
  }

  static put(route: string, data?: any, params?: object, appendHeaders?: object): Promise<any> {
    return Api.request(route, params, data, Api.methods.PUT, appendHeaders);
  }

  static patch(route: string, params?: KeyValueModel<string>, data?: object) {
    return Api.request(route, params, data, Api.methods.PATCH);
  }

  static post(route: string, data?: any, appendHeaders?: object): Promise<any> {
    return Api.request(route, undefined, data, Api.methods.POST, appendHeaders);
  }

  static delete(route: string, params?: object) {
    return Api.request(route, params, undefined, Api.methods.DELETE);
  }

  static async request(
    route: string,
    params: object | undefined,
    data: object | undefined,
    method: any,
    appendHeaders?: object,
    callback?: (response: any) => void,
  ): Promise<any> {
    const url = Api.composeRouteUrl(route);

    let headers: any = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
    };

    let token = localStorage.getItem('token');
    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` };
    }
    if (appendHeaders) {
      headers = { ...headers, ...appendHeaders };
    }

    return axios({
      method,
      url,
      headers,
      params,
      data,
    })
      .then(resp => {
        return resp.data;
      })
      .catch(async err => {
        console.log('err', err);
        const { response } = err;
        if (response.status === 401) {
          localStorage.removeItem('token');
          const logoutEvent = new CustomEvent(LOGOUT_EVENT);
          window.dispatchEvent(logoutEvent);
        }
      });
  }

  static handleError(error: any, route?: string) {
    const response = error.response || error;
    console.log('route', route);
    console.log(`Error occurred\n${response.status} ${response.data?.code}`);
  }
}
