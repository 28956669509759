export enum PostTypes {
  ON_LOGIN = 'ON_LOGIN',
  ON_LOGOUT = 'ON_LOGOUT',
  ON_COMPANY_SELECT = 'ON_COMPANY_SELECT',
}

type createMessage<Type, Data> = {
  type: Type;
  data: Data;
};

type createEvent<Type, Data> = {
  type: Type;
  callback: (data: Data) => void;
};

type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

export namespace OnLogin {
  export type Data = {
    token: string;
    user_id: number;
  };

  type Type = PostTypes.ON_LOGIN;

  export type Message = createMessage<Type, Data>;
  export type Event = createEvent<Type, Data>;
}

export namespace OnLogout {
  export type Data = {};

  type Type = PostTypes.ON_LOGOUT;

  export type Message = Optional<createMessage<Type, Data>, 'data'>;
  export type Event = createEvent<Type, Data>;
}

export namespace OnCompanySelect {
  export type Data = {
    selected_client_id: number;
  };

  type Type = PostTypes.ON_COMPANY_SELECT;

  export type Message = createMessage<Type, Data>;
  export type Event = createEvent<Type, Data>;
}

export type PostMessage = OnLogin.Message | OnLogout.Message | OnCompanySelect.Message;

export type PostEvent = OnLogin.Event | OnLogout.Event | OnCompanySelect.Event;
