import { ISelectOptions } from '../../models/global';
import { FlowLinesInterpolationModel, InterpolationModeType, InterpolationType } from '../../models';

export const interpolationOptions: FlowLinesInterpolationModel[] = [
  {
    label: 'Continuous',
    value: 'continuous',
    type: 'mode',
  },
  {
    label: 'Stair-Start',
    value: 'stair-start',
    type: 'mode',
  },
  {
    label: 'Stair-End',
    value: 'stair-end',
    type: 'mode',
  },
  {
    label: 'Manual',
    value: 'custom',
    type: 'inter',
  },
];

export const interpolationModOptions: FlowLinesInterpolationModel[] = [
  {
    label: 'Continuous',
    value: 'continuous',
    type: 'mode',
  },
  {
    label: 'Stair-Start',
    value: 'stair-start',
    type: 'mode',
  },
  {
    label: 'Stair-End',
    value: 'stair-end',
    type: 'mode',
  },
  {
    label: 'Leave empty',
    value: 'empty',
    type: 'mode',
  },
];
