export const lightTheme = {
  colors: {
    background: {
      grid: '#D2D7DF', // btn border | btn background | table border | SandBox
      header: '#E2E7EC',
      sheet: '#F1F4F8', // Popup header|
      main: '#FFFFFF', // Popup body| main background | fields
      color1: '#A5B0C6', // Request header |
      color2: '#FFBC71', // Commitment header | NF | GMP
      color3: '#A5B0C6', // SandBox active
      color4: '#E2E7EC', // SandBox DUE UPD NEW
      border: '1px solid #D2D7DF',
      tour: '#FFFFFF',
      isDeactivated: '#F1F4F8', // SandBox Request card add deactivated status
      carousel: '#D2D7DF', // Carousel in ProjectGrid
      arrow: '#BCC1C8', // Carousel arrow in ProjectGrid
    },
    popup: {
      header: '#D2D7DF',
      content: '#E2E7EC',
    },
    text: {
      main: '#38455E',
      secondary: '#838EA2',
      noSelect: '#B4BDCF', // Labels | No selected
      colorSelect: '#38455E', // SandBox text
      colorNoSelect: '#838EA2', // SandBox text
      helpMenuColor: '#38455E',
    },
    buttons: {
      default: '#F1F4F8',
      hoverMenu: '#F1F4F8',
      activeMenu: '#D2D7DF',
      defaultMenuText: '#38455E',
      hoverMenuText: '#B4BDCF',
      activeMenuText: '#38455E',
      deactivated: '#B4BDCF',
    },
    // Btn primal
    buttons2: {
      unactive: '#E2E7EC',
      active: '#A5B0C6',
      hover: '#FFBC71',
      unactiveText: '#B4BDCF',
      activeText: '#FFFFFF',
      hoverText: '#FFFFFF',
    },
    // Button
    generalButton: {
      default: 'transparent',
      defaultBorder: '1px solid #D2D7DF',
      hover: '#E2E7EC',
      hoverBorder: '1px solid #E2E7EC',
      defaultText: '#38455E',
      hoverText: '#B4BDCF',
    },
    // Button Accept
    buttonAccept: {
      default: '#A5B0C6',
      defaultText: '#FFFFFF',
      hover: '#B4BDCF',
      hoverText: '#FFFFFF',
      disable: '#D2D7DF',
      disableText: '#838EA2',
    },
    // Button Decline
    buttonDecline: {
      default: '#ED5147',
      defaultText: '#ECEFF2',
      hover: '#FFBC71',
      hoverText: '#ECEFF2',
      disable: '#D2D7DF',
      disableText: '#838EA2',
    },
    buttonPullRequest: {
      default: 'transparent',
      defaultBorder: '1px solid #38455E',
      defaultText: '#38455E',
      hover: 'transparent',
      hoverBorder: '1px solid #F3A359',
      hoverText: '#F3A359',
      disable: 'transparent',
      disableBorder: 'border: 1px solid #D2D7DF',
      alternateBorder: '1px solid #D2D7DF',
      disableText: '#838EA2',
    },
    // Buttons Cancel
    outlinedButton: {
      default: 'transparent',
      defaultBorder: '1px solid #838EA2',
      disableBorder: '1px solid #D2D7DF',
      hover: '#E2E7EC',
      disabled: '#E2E7EC',
      defaultText: '#38455E',
      hoverText: '#38455E',
      disabledText: '#B4BDCF',
    },
    orange: {
      default: '#FFBC71',
      defaultText: '#FFFFFF',
      hover: '#F3A359',
      hoverText: '#FFFFFF',
      disable: '#E2E7EC',
      disableText: '#B4BDCF',
    },
    // Change a company \ Change a dueDate
    pourButton: {
      default: '#E2E7EC',
      defaultText: '#38455E',
      hover: '#B4BDCF',
      hoverText: '#38455E ',
      disable: '#E2E7EC',
      disableText: '#B4BDCF',
    },
    commentButton: {
      default: '#FFFFFF',
      defaultText: '#38455E',
      hover: '#D2D7DF',
      hoverText: '#38455E',
      disable: '#E2E7EC',
      disableText: '#B4BDCF',
    },
    buildingButton: {
      default: '#E2E7EC',
      defaultText: '#38455E',
      hover: '#A5B0C6',
      hoverText: '#38455E',
      disable: '#E2E7EC',
      disableText: '#A5B0C6',
    },
    cancelButton: {
      default: 'transparent',
      defaultBorder: ' 1px solid #838EA2;',
      disableBorder: '1px solid #D2D7DF',
      hover: '#B4BDCF',
      disabled: 'transparent',
      defaultText: '#38455E',
      hoverText: '#38455E',
      disabledText: '#D2D7DF',
    },
    tags: {
      tag1: '#778EA4',
      tag2: '#55738E',
      tag3: '#365C7E',
      color: '#fff',
    },
    notificationBell: {
      counter: 'yellow',
      today: '#65C17F',
    },
    phasePlan: {
      lod350: '#BDC4D4',
      lod325: '#A5B0C6',
      lod300: '#8E9CB9',
      lod290: '#7688AB',
      lod200: '#5F749E',
      permit: '#4684BA',
      construction: '#98A2BA',
      color: '#FFFFFF',
    },
    permissions: {
      color1: '#263C5C',
      color2: '#3A5676',
      color3: '#517597',
      color4: '#7392AF',
    },
    driverMode: {
      mainDriver: '#FFBC71',
      selectedDriver: '#F3A359',
      possibleDriver: '#5E739E',
    },
    switcherThemeIcon: {
      sun: '#8FA6BB',
      moon: '#E5E9ED',
    },
    rest: {
      predecessorColor: '#38455E',
      predecessorBackground: '#D2D7DF',
    },
    select: {
      color: '#38455E',
      background: '#E2E7EC',
      backgroundHover: '#F1F4F8',
      hoverText: '#38455E',
      backgroundActive: '#F1F4F8',
    },
    navIcons: {
      active: '#596989',
      hover: '#909BB0',
      default: '#909BB0',
    },
    logo: {
      part1: '#5E739D',
      part2: '#A5B0C6',
      text: '#F1F4F8',
    },
    dropDown: {
      default: '#2D384E',
      hover: '#F3A359',
      active: '#B3C3D3',
    },
    multiSelect: {
      defaultText: '#38455E',
      hoveredText: '#F3A359',
    },
    multiSelectSearch: {
      default: '#FFFFFF',
    },
    tooltip: {
      default: '#D2D7DF',
      defaultText: '#38455E',
    },
    checkbox: {
      default: '#838EA2',
    },
    // Button for more files
    fileButtons: {
      showMore: '#838EA1',
      attachFile: '#838EA1',
    },
    svgIcons: {
      color: '#838EA2',
      arrowColor: '#838EA2',
      removeIconBackground: '#D2D7DF',
      removeIconColor: '#838EA2',
    },
  },
  fonts: {
    general: 'Proxima Nova',
    menuItemSize: '12px',
    weight: '600',
  },
  images: {},
  svgImages: {
    settings: {
      default: '#5F6875',
      hover: '#F3A359',
      active: '#8BA1B6',
    },
    requestNavigation: {
      default: '#FFFFFF',
      hover: '#F3A359',
      disable: '#2D384E',
    },
  },
};
