import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import { getUserInfo, getUserInfoSuccess, setKpiDisplaySettings, setKpiDisplaySettingsSuccess } from './authoriztionSlice';
import Api from '../../services/api';
import { PayloadModel } from '../../models/global';
import { KpiDisplayOptionsModel, UserPayloadModel } from '../../components/common/toolbar/settingsPanel/settingsPanel.types';

function* getUserInfoSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { id },
    }: { payload: UserPayloadModel } = yield take(getUserInfo.toString());

    try {
      const response = yield call(Api.get, `user-short/${id}`);
      localStorage.removeItem('prevBuildingId');
      if (response) {
        yield put(getUserInfoSuccess(response.data.resource));
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* setKpiDisplaySettingsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<KpiDisplayOptionsModel, null> = yield take(setKpiDisplaySettings.toString());

    try {
      const response = yield call(Api.post, `kpi-set-user-options`, { data: data });
      if (response) {
        yield put(setKpiDisplaySettingsSuccess(response.data.resource));
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

export function* saga() {
  yield all([getUserInfoSaga(), setKpiDisplaySettingsSaga()]);
}
