// Fields for "Hide/Show columns"
export const switchColumns = {
  ALL: 'ALL',
  REQUEST_DISCIPLINE: 'REQUEST_DISCIPLINE',
  LOCATION: 'LOCATION',
  BUILDING_SPACES: 'BUILDING SPACES',
  BUILDING: 'BUILDING',
  RESPONSE_COMPANY: 'RESPONSE_COMPANY',
  REQUEST_COMPANY: 'REQUEST_COMPANY',
  PREDECESSORS: 'PREDECESSORS',
  PROJECT_SPECIFIC_DESCRIPTION: 'PROJECT_SPECIFIC_DESCRIPTION',
  NAME: 'NAME',
  REQUEST_MANAGER: 'REQUEST_MANAGER',
  RESPONSE_MANAGER: 'RESPONSE_MANAGER',
  EMAIL: 'EMAIL',
  NF: 'NF',
  SOURCE: 'SOURCE',
  INCORPORATED: 'INCORPORATED',
  INCORPORATED_BY: 'INCORPORATED_BY',
  INCORPORATED_LEVEL: 'INCORPORATED_LEVEL',
  INCORPORATED_TO: 'INCORPORATED_TO',
  DESCRIPTION: 'DESCRIPTION',
  SUCCESSOR: 'SUCCESSOR',
  COMMITMENT: 'COMMITMENT',
  COMMITMENT_FINAL: 'COMMITMENT_FINAL',
  NF_STATUS: 'NF_STATUS',
  DUE_DATE: 'DUE_DATE',
  CREATED: 'CREATED',
  UFC: 'UFC',
  MFC: 'MFC',
  EDIT: 'EDIT',
  REQUEST_FILES: 'REQUEST_FILES',
  COMMITMENT_FILES: 'COMMITMENT_FILES',
  RESPONSE_DISCIPLINE: 'RESPONSE_DISCIPLINE',
  MILESTONE: 'MILESTONE',
  HASHTAGS: 'HASHTAGS',
  COMMITMENT_FINAL_FILE: 'COMMITMENT_FINAL_FILE',
  DELETE: 'DELETE',
  NF_ACTION_BY: 'NF_ACTION_BY',
  NF_ACTION_DATE: 'NF_ACTION_DATE',
  DELIVERABLE_AUTHOR: 'DELIVERABLE_AUTHOR',
  COMMENTS: 'COMMENTS',
  COMMENT_AUTHOR: 'COMMENT_AUTHOR',
  COMMENT_DATE: 'COMMENT_DATE',
  COMMENT_MENTION: 'COMMENT_MENTION',
  MY_REQUEST: 'MY_REQUEST',
  BALL_IN_COURT: 'BALL_IN_COURT',
  PRIVATE: 'PRIVATE',
  WATCHERS: 'WATCHERS',
  //PCDFilters
  CARD_STATUS: 'CARD_STATUS',
  REVIEW_STATE: 'REVIEW_STATE',
  DELIVERABLE_TYPE: 'DELIVERABLE_TYPE',
  DELIVERABLE_DISCIPLINE: 'DELIVERABLE_DISCIPLINE',
  RESPONSIBLE_PARTY: 'RESPONSIBLE_PARTY',
  RESPONSIBLE_PARTY_MANAGER: 'RESPONSIBLE_PARTY_MANAGER',
  REVIEW_COMPANY: 'REVIEW_COMPANY',
  REVIEW_MANAGER: 'REVIEW_MANAGER',
  DELIVERABLE_NAME: 'DELIVERABLE_NAME',
};

// Fields for get filters from API
export const filterFields = {
  LOCATION: 'locations',
  BUILDING_SPACES: 'building_spaces',
  BUILDING: 'buildings',
  REQUEST_DISCIPLINE: 'request_disciplines',
  RESPONSE_COMPANY: 'response_party',
  REQUEST_COMPANY: 'request_party',
  PREDECESSORS: 'successors',
  PROJECT_SPECIFIC_DESCRIPTION: 'request_description',
  NAME: 'authors',
  REQUEST_MANAGER: 'users',
  RESPONSE_MANAGER: 'response_managers',
  EMAIL: 'commitment_users',
  NF: 'nf',
  DESCRIPTION: 'subject',
  SUCCESSOR: 'predecessors',
  COMMITMENT: 'commitment_statement',
  COMMITMENT_FINAL: 'final_deliverable',
  DUE_DATE: 'due_date',
  REQUEST_FILE: 'request_files',
  CREATED: 'created_at',
  UFC: 'ufc',
  MFC: 'mf',
  RESPONSE_DISCIPLINE: 'response_disciplines',
  SOURCE: 'tags',
  INCORPORATED: 'incorporated',
  INCORPORATED_BY: 'incorporated_by',
  INCORPORATED_LEVEL: 'incorporated_level',
  INCORPORATED_TO: 'incorporated_to',
  NF_STATUS: 'nf_status',
  HASHTAGS: 'hashtags',
  MILESTONE: 'milestones',
  COMMITMENT_FILE: 'commitment_files',
  COMMITMENT_FINAL_FILE: 'deliverable_files',
  NF_ACTION_BY: 'nf_action_by',
  NF_ACTION_DATE: 'nf_action_date',
  DELIVERABLE_AUTHOR: 'deliverable_author',
  COMMENTS: 'comments',
  COMMENT_DATE: 'comment_date',
  COMMENT_AUTHOR: 'comment_author',
  COMMENT_MENTION: 'comment_mention',
  MY_REQUEST: 'my_request',
  BALL_IN_COURT: 'ball_in_court',
  PRIVATE: 'private',
  WATCHERS: 'watchers',
  //PCDFilter
  CARD_STATUS: 'card_status',
  REVIEW_STATE: 'review_state',
  DELIVERABLE_TYPE: 'deliverable_type',
  DELIVERABLE_DISCIPLINE: 'deliverable_discipline',
  RESPONSIBLE_PARTY: 'responsible_party',
  RESPONSIBLE_PARTY_MANAGER: 'responsible_party_manager',
  REVIEW_COMPANY: 'review_company',
  REVIEW_MANAGER: 'review_manager',
  DELIVERABLE_NAME: 'deliverable_name',
};

// Request fields for search by column
export const searchFields = {
  LOCATION: 'location',
  BUILDING_SPACES: 'building_spaces',
  BUILDING: 'building',
  REQUEST_DISCIPLINE: 'request_discipline',
  RESPONSE_COMPANY: 'response_party',
  REQUEST_COMPANY: 'request_party',
  PREDECESSORS: 'successors',
  PROJECT_SPECIFIC_DESCRIPTION: 'project_specific',
  NAME: 'author',
  REQUEST_MANAGER: 'user',
  RESPONSE_MANAGER: 'response_manager',
  EMAIL: 'commitment_author',
  NF: 'nf',
  DESCRIPTION: 'project_specific_description',
  SUCCESSOR: 'predecessors',
  COMMITMENT: 'commitment',
  COMMITMENT_FINAL: 'final_desc',
  DUE_DATE: 'due_date',
  CREATED: 'created_at',
  UFC: 'ufc',
  MFC: 'mfc',
  RESPONSE_DISCIPLINE: 'response_discipline',
  SOURCE: 'tag',
  INCORPORATED: 'incorporated',
  INCORPORATED_BY: 'incorporated_by',
  INCORPORATED_LEVEL: 'incorporated_level',
  INCORPORATED_TO: 'incorporated_to',
  REQUEST_FILE: 'request_file',
  COMMITMENT_FILE: 'commitment_file',
  HASHTAGS: 'hashtags',
  MILESTONE: 'milestone',
  NF_STATUS: 'nf_status',
  COMMITMENT_FINAL_FILE: 'commitment_final_file',
  NF_ACTION_BY: 'nf_action_by',
  NF_ACTION_DATE: 'nf_action_date',
  DELIVERABLE_AUTHOR: 'deliverable_author',
  COMMENTS: 'comments',
  COMMENT_DATE: 'comment_date',
  COMMENT_AUTHOR: 'comment_author',
  COMMENT_MENTION: 'comment_mention',
  MY_REQUEST: 'my_request',
  BALL_IN_COURT: 'ball_in_court',
  PRIVATE: 'private',
  WATCHERS: 'watchers',
  //PCDFilter
  CARD_STATUS: 'card_status',
  REVIEW_STATE: 'review_state',
  DELIVERABLE_TYPE: 'deliverable_type',
  DELIVERABLE_DISCIPLINE: 'deliverable_discipline',
  RESPONSIBLE_PARTY: 'responsible_party',
  RESPONSIBLE_PARTY_MANAGER: 'responsible_party_manager',
  REVIEW_COMPANY: 'review_company',
  REVIEW_MANAGER: 'review_manager',
  DELIVERABLE_NAME: 'deliverable_name',
};

/*
 * title - Column name
 * isChecked - Hide\show column
 * value - Property for switch hide\show column
 * width - Column width
 * filterField - Property for get filter from filters collection
 * searchField - Column search field
 * exportField - For export column like: PDF
 * sortingField - Column sorting
 * editFieldName - For edit field
 * filteredField - Send filter property to API for filtration
 * */
export const list_columns = [
  {
    title: 'All',
    isChecked: false,
    value: switchColumns.ALL,
    position: 0,
  },
  {
    title: 'NF',
    isChecked: true,
    value: switchColumns.NF,
    width: 72,
    filterField: filterFields.NF,
    searchField: searchFields.NF,
    exportField: 'nf',
    sortingField: 'nf',
    position: 1,
  },
  {
    title: 'Request type',
    isChecked: true,
    value: switchColumns.SOURCE,
    width: 128,
    filterField: filterFields.SOURCE,
    searchField: searchFields.SOURCE,
    exportField: 'tag',
    sortingField: 'tag',
    editFieldName: 'tag_ids',
    filteredField: 'tag_ids',
    position: 2,
  },
  {
    title: 'NF status',
    isChecked: true,
    value: switchColumns.NF_STATUS,
    filterField: filterFields.NF_STATUS,
    width: 128,
    exportField: 'nf_status',
    filteredField: 'nf_status',
    position: 3,
  },
  {
    title: 'NF action by',
    isChecked: true,
    value: switchColumns.NF_ACTION_BY,
    filterField: filterFields.NF_ACTION_BY,
    width: 148,
    exportField: 'nf_action_by',
    sortingField: 'nf_action_by',
    filteredField: 'nf_action_by',
    position: 1,
  },
  {
    title: 'NF action date',
    isChecked: true,
    value: switchColumns.NF_ACTION_DATE,
    filterField: filterFields.NF_ACTION_DATE,
    width: 148,
    exportField: 'nf_action_date',
    sortingField: 'nf_action_date',
    filteredField: 'nf_action_date',
    position: 2,
  },
  {
    title: 'Private',
    isChecked: true,
    value: switchColumns.PRIVATE,
    width: 98,
    filterField: filterFields.PRIVATE,
    searchField: searchFields.PRIVATE,
    exportField: 'private',
    sortingField: 'private',
    filteredField: 'private',
    position: 7,
  },
  {
    title: 'UniFormat',
    isChecked: true,
    value: switchColumns.UFC,
    width: 98,
    filterField: filterFields.UFC,
    searchField: searchFields.UFC,
    exportField: 'ufc',
    sortingField: 'ufc',
    editFieldName: 'ufc_ids',
    filteredField: 'ufc_ids',
    position: 5,
  },
  {
    title: 'MasterFormat',
    isChecked: true,
    value: switchColumns.MFC,
    width: 98,
    filterField: filterFields.MFC,
    searchField: searchFields.MFC,
    exportField: 'mf',
    sortingField: 'mfc',
    editFieldName: 'mfc_ids',
    filteredField: 'mfc_ids',
    position: 6,
  },
  {
    title: 'Incorporated',
    isChecked: true,
    value: switchColumns.INCORPORATED,
    width: 148,
    filterField: filterFields.INCORPORATED,
    searchField: searchFields.INCORPORATED,
    exportField: 'incorporated',
    sortingField: 'incorporated',
    editFieldName: 'incorporated',
    filteredField: 'incorporated',
    position: 1,
  },
  {
    title: 'Incorporated by',
    isChecked: true,
    value: switchColumns.INCORPORATED_BY,
    width: 148,
    filterField: filterFields.INCORPORATED_BY,
    searchField: searchFields.INCORPORATED_BY,
    exportField: 'incorporated_by',
    sortingField: 'incorporated_by',
    editFieldName: 'incorporated_by',
    filteredField: 'incorporated_by',
    position: 10,
  },
  {
    title: 'Incorporation level',
    isChecked: true,
    value: switchColumns.INCORPORATED_LEVEL,
    width: 148,
    filterField: filterFields.INCORPORATED_LEVEL,
    searchField: searchFields.INCORPORATED_LEVEL,
    exportField: 'incorporated_level',
    sortingField: 'incorporated_level',
    editFieldName: 'incorporated_level',
    filteredField: 'incorporated_level',
    position: 11,
  },
  {
    title: 'Incorporated to',
    isChecked: true,
    value: switchColumns.INCORPORATED_TO,
    width: 148,
    filterField: filterFields.INCORPORATED_TO,
    searchField: searchFields.INCORPORATED_TO,
    exportField: 'incorporated_to',
    sortingField: 'incorporated_to',
    editFieldName: 'incorporated_to',
    filteredField: 'incorporated_to',
    position: 12,
  },
  {
    title: 'LOD milestone',
    isChecked: true,
    value: switchColumns.MILESTONE,
    width: 148,
    filterField: filterFields.MILESTONE,
    searchField: searchFields.MILESTONE,
    exportField: 'milestone',
    sortingField: 'milestone',
    editFieldName: 'milestone_id',
    filteredField: 'milestones',
    position: 8,
  },
  {
    title: 'Building',
    isChecked: true,
    value: switchColumns.BUILDING,
    width: 148,
    filterField: filterFields.BUILDING,
    searchField: searchFields.BUILDING,
    exportField: 'building',
    sortingField: 'buildings',
    editFieldName: 'buildings',
    filteredField: 'buildings',
    position: 1,
  },
  {
    title: 'Level',
    isChecked: true,
    value: switchColumns.LOCATION,
    width: 148,
    filterField: filterFields.LOCATION,
    searchField: searchFields.LOCATION,
    exportField: 'location',
    sortingField: 'locations',
    editFieldName: 'locations',
    filteredField: 'locations',
    position: 2,
  },
  {
    title: 'Building space',
    isChecked: true,
    value: switchColumns.BUILDING_SPACES,
    width: 148,
    filterField: filterFields.BUILDING_SPACES,
    searchField: searchFields.BUILDING_SPACES,
    exportField: 'building_space',
    sortingField: 'building_spaces',
    editFieldName: 'building_spaces',
    filteredField: 'building_spaces',
    position: 3,
  },
  {
    title: 'Tags',
    isChecked: true,
    value: switchColumns.HASHTAGS,
    width: 148,
    filterField: filterFields.HASHTAGS,
    searchField: searchFields.HASHTAGS,
    exportField: 'hashtags',
    sortingField: 'hashtags',
    editFieldName: 'hashtags',
    filteredField: 'hashtags',
    position: 9,
  },
  {
    title: 'Subject',
    isChecked: true,
    value: switchColumns.DESCRIPTION,
    width: 220,
    filterField: filterFields.DESCRIPTION,
    searchField: searchFields.DESCRIPTION,
    exportField: 'project_specific_description',
    sortingField: 'project_specific_description',
    position: 4,
  },
  {
    title: 'Request discipline',
    isChecked: true,
    value: switchColumns.REQUEST_DISCIPLINE,
    width: 198,
    filterField: filterFields.REQUEST_DISCIPLINE,
    searchField: searchFields.REQUEST_DISCIPLINE,
    exportField: 'request_discipline',
    sortingField: 'request_discipline',
    editFieldName: 'req_disc_id',
    filteredField: 'request_disciplines',
    position: 1,
  },
  {
    title: 'Request company',
    isChecked: true,
    value: switchColumns.REQUEST_COMPANY,
    width: 198,
    filterField: filterFields.REQUEST_COMPANY,
    searchField: searchFields.REQUEST_COMPANY,
    exportField: 'request_party',
    sortingField: 'request_party',
    editFieldName: 'request_party_id',
    filteredField: 'request_party',
    position: 2,
  },
  {
    title: 'Request author',
    isChecked: true,
    value: switchColumns.NAME,
    width: 148,
    filterField: filterFields.NAME,
    searchField: searchFields.NAME,
    exportField: 'author',
    sortingField: 'author',
    filteredField: 'authors',
    position: 4,
  },
  {
    title: 'Request manager',
    isChecked: true,
    value: switchColumns.REQUEST_MANAGER,
    width: 178,
    filterField: filterFields.REQUEST_MANAGER,
    searchField: searchFields.REQUEST_MANAGER,
    exportField: 'user',
    sortingField: 'user',
    editFieldName: 'user_id',
    filteredField: 'users',
    position: 3,
  },
  {
    title: 'Request description',
    isChecked: true,
    value: switchColumns.PROJECT_SPECIFIC_DESCRIPTION,
    width: 220,
    filterField: filterFields.PROJECT_SPECIFIC_DESCRIPTION,
    searchField: searchFields.PROJECT_SPECIFIC_DESCRIPTION,
    exportField: 'project_specific',
    sortingField: 'project_specific',
    position: 23,
  },
  {
    title: 'Request files',
    isChecked: true,
    value: switchColumns.REQUEST_FILES,
    width: 198,
    filterField: filterFields.REQUEST_FILE,
    searchField: searchFields.REQUEST_FILE,
    exportField: 'request_file',
    position: 24,
  },
  {
    title: 'NF created',
    isChecked: true,
    value: switchColumns.CREATED,
    width: 97,
    filterField: filterFields.CREATED,
    searchField: searchFields.CREATED,
    exportField: 'created_at',
    sortingField: 'created_at',
    position: 3,
  },
  {
    title: 'NF due date',
    isChecked: true,
    value: switchColumns.DUE_DATE,
    width: 98,
    filterField: filterFields.DUE_DATE,
    searchField: searchFields.DUE_DATE,
    exportField: 'due_date',
    sortingField: 'due_date',
    editFieldName: 'due_date',
    position: 4,
  },
  {
    title: 'Response discipline',
    isChecked: true,
    value: switchColumns.RESPONSE_DISCIPLINE,
    width: 198,
    filterField: filterFields.RESPONSE_DISCIPLINE,
    searchField: searchFields.RESPONSE_DISCIPLINE,
    exportField: 'response_discipline',
    sortingField: 'response_discipline',
    editFieldName: 'resp_disc_id',
    filteredField: 'response_disciplines',
    position: 5,
  },
  {
    title: 'Response company',
    isChecked: true,
    value: switchColumns.RESPONSE_COMPANY,
    width: 198,
    filterField: filterFields.RESPONSE_COMPANY,
    searchField: searchFields.RESPONSE_COMPANY,
    exportField: 'response_party',
    sortingField: 'response_party',
    editFieldName: 'response_party_id',
    filteredField: 'response_party',
    position: 6,
  },
  {
    title: 'Response manager',
    isChecked: true,
    value: switchColumns.RESPONSE_MANAGER,
    width: 178,
    filterField: filterFields.RESPONSE_MANAGER,
    searchField: searchFields.RESPONSE_MANAGER,
    exportField: 'response_manager',
    sortingField: 'response_manager',
    editFieldName: 'response_user_id',
    filteredField: 'response_managers',
    position: 7,
  },
  {
    title: 'Commitment statement',
    isChecked: true,
    value: switchColumns.COMMITMENT,
    width: 198,
    filterField: filterFields.COMMITMENT,
    searchField: searchFields.COMMITMENT,
    exportField: 'commitment',
    sortingField: 'commitment',
    position: 1,
  },
  {
    title: 'Commitment files',
    isChecked: true,
    value: switchColumns.COMMITMENT_FILES,
    width: 198,
    searchField: searchFields.COMMITMENT_FILE,
    filterField: filterFields.COMMITMENT_FILE,
    exportField: 'commitment_file',
    position: 2,
  },
  {
    title: 'Commitment author',
    isChecked: true,
    value: switchColumns.EMAIL,
    width: 198,
    filterField: filterFields.EMAIL,
    searchField: searchFields.EMAIL,
    exportField: 'commitment_author',
    sortingField: 'email',
    position: 3,
  },
  {
    title: 'Deliverable description',
    isChecked: true,
    value: switchColumns.COMMITMENT_FINAL,
    width: 220,
    filterField: filterFields.COMMITMENT_FINAL,
    searchField: searchFields.COMMITMENT_FINAL,
    exportField: 'final_desc',
    sortingField: 'final_desc',
    position: 4,
  },
  {
    title: 'Deliverable files',
    isChecked: true,
    value: switchColumns.COMMITMENT_FINAL_FILE,
    width: 198,
    searchField: searchFields.COMMITMENT_FINAL_FILE,
    filterField: filterFields.COMMITMENT_FINAL_FILE,
    exportField: 'commitment_final_file',
    position: 5,
  },
  {
    title: 'Deliverable author',
    isChecked: true,
    value: switchColumns.DELIVERABLE_AUTHOR,
    filterField: filterFields.DELIVERABLE_AUTHOR,
    width: 198,
    filteredField: 'deliverable_author',
    exportField: 'deliverable_author',
    sortingField: 'deliverable_author',
    position: 6,
  },
  {
    title: 'Comments',
    isChecked: true,
    value: switchColumns.COMMENTS,
    filterField: filterFields.COMMENTS,
    width: 285,
    filteredField: 'comments',
    exportField: 'comments',
    sortingField: 'comments',
    position: 1,
  },
  {
    title: 'Predecessor',
    isChecked: true,
    value: switchColumns.PREDECESSORS,
    width: 148,
    filterField: filterFields.PREDECESSORS,
    searchField: searchFields.PREDECESSORS,
    exportField: 'successors',
    sortingField: 'sucсessor',
    position: 10,
  },
  {
    title: 'Successors',
    isChecked: true,
    value: switchColumns.SUCCESSOR,
    width: 148,
    filterField: filterFields.SUCCESSOR,
    searchField: searchFields.SUCCESSOR,
    exportField: 'predecessors',
    sortingField: 'predecessors',
    position: 11,
  },
  {
    title: 'Comment date',
    isChecked: false,
    value: switchColumns.COMMENT_DATE,
    filterField: filterFields.COMMENT_DATE,
    width: 100,
    position: 39,
  },
  {
    title: 'Comment author',
    isChecked: false,
    value: switchColumns.COMMENT_AUTHOR,
    filterField: filterFields.COMMENT_AUTHOR,
    width: 100,
    position: 40,
  },
  {
    title: 'Comment mentions',
    isChecked: false,
    value: switchColumns.COMMENT_MENTION,
    filterField: filterFields.COMMENT_MENTION,
    width: 100,
    position: 41,
  },
  {
    title: 'My request',
    isChecked: false,
    value: switchColumns.MY_REQUEST,
    filterField: filterFields.MY_REQUEST,
    width: 60,
    position: 42,
  },
  {
    title: 'Ball-in-Court',
    isChecked: true,
    value: switchColumns.BALL_IN_COURT,
    width: 198,
    filterField: filterFields.BALL_IN_COURT,
    searchField: searchFields.BALL_IN_COURT,
    exportField: 'ball_in_court',
    sortingField: 'ball_in_court',
    filteredField: 'ball_in_court',
    position: 43,
  },
  {
    title: 'Watchers',
    isChecked: true,
    value: switchColumns.WATCHERS,
    width: 198,
    filterField: filterFields.WATCHERS,
    searchField: searchFields.WATCHERS,
    exportField: 'watchers',
    sortingField: 'watchers',
    filteredField: 'watchers',
    position: 44,
  },
  //PCDFilters
  {
    title: 'Submission status',
    isChecked: true,
    value: switchColumns.CARD_STATUS,
    width: 100,
    filterField: filterFields.CARD_STATUS,
    searchField: searchFields.CARD_STATUS,
    exportField: 'card_status',
    sortingField: 'card_status',
    filteredField: 'card_status',
    position: 0,
  },
  {
    title: 'Review status',
    isChecked: true,
    value: switchColumns.REVIEW_STATE,
    width: 100,
    filterField: filterFields.REVIEW_STATE,
    searchField: searchFields.REVIEW_STATE,
    exportField: 'review_state',
    sortingField: 'review_state',
    filteredField: 'review_state',
    position: 1,
  },
  {
    title: 'Deliverable type',
    isChecked: true,
    value: switchColumns.DELIVERABLE_TYPE,
    width: 100,
    filterField: filterFields.DELIVERABLE_TYPE,
    searchField: searchFields.DELIVERABLE_TYPE,
    exportField: 'deliverable_type',
    sortingField: 'deliverable_type',
    filteredField: 'deliverable_type',
    position: 2,
  },
  {
    title: 'Deliverable discipline',
    isChecked: true,
    value: switchColumns.DELIVERABLE_DISCIPLINE,
    width: 130,
    filterField: filterFields.DELIVERABLE_DISCIPLINE,
    searchField: searchFields.DELIVERABLE_DISCIPLINE,
    exportField: 'deliverable_discipline',
    sortingField: 'deliverable_discipline',
    filteredField: 'deliverable_discipline',
    position: 3,
  },
  {
    title: 'Responsible party company',
    isChecked: true,
    value: switchColumns.RESPONSIBLE_PARTY,
    width: 180,
    filterField: filterFields.RESPONSIBLE_PARTY,
    searchField: searchFields.RESPONSIBLE_PARTY,
    exportField: 'responsible_party',
    sortingField: 'responsible_party',
    filteredField: 'responsible_party',
    position: 4,
  },
  {
    title: 'Responsible party manager',
    isChecked: true,
    value: switchColumns.RESPONSIBLE_PARTY_MANAGER,
    width: 180,
    filterField: filterFields.RESPONSIBLE_PARTY_MANAGER,
    searchField: searchFields.RESPONSIBLE_PARTY_MANAGER,
    exportField: 'responsible_party_manager',
    sortingField: 'responsible_party_manager',
    filteredField: 'responsible_party_manager',
    position: 5,
  },
  {
    title: 'Review party company',
    isChecked: true,
    value: switchColumns.REVIEW_COMPANY,
    width: 180,
    filterField: filterFields.REVIEW_COMPANY,
    searchField: searchFields.REVIEW_COMPANY,
    exportField: 'review_company',
    sortingField: 'review_company',
    filteredField: 'review_company',
    position: 6,
  },
  {
    title: 'Review party manager',
    isChecked: true,
    value: switchColumns.REVIEW_MANAGER,
    width: 180,
    filterField: filterFields.REVIEW_MANAGER,
    searchField: searchFields.REVIEW_MANAGER,
    exportField: 'review_manager',
    sortingField: 'review_manager',
    filteredField: 'review_manager',
    position: 7,
  },
  {
    title: 'Deliverable name',
    isChecked: true,
    value: switchColumns.DELIVERABLE_NAME,
    width: 130,
    filterField: filterFields.DELIVERABLE_NAME,
    searchField: searchFields.DELIVERABLE_NAME,
    exportField: 'deliverable_name',
    sortingField: 'deliverable_name',
    filteredField: 'deliverable_name',
    position: 8,
  },
];

export const commitementStatusFilter = [
  {
    id: 'new',
    title: 'New',
    value: 'new',
    isChecked: false,
  },
  {
    id: 'inprogress',
    title: 'Submitted',
    value: 'inprogress',
    isChecked: false,
  },
  {
    id: 'declined',
    title: 'Declined',
    value: 'declined',
    isChecked: false,
  },
  {
    id: 'completed',
    title: 'Completed',
    value: 'completed',
    isChecked: false,
  },
  {
    id: 'accepted',
    title: 'Accepted',
    value: 'accepted',
    isChecked: false,
  },
];
export const requestStatusFilter = [
  {
    id: 'new',
    title: 'Sent',
    value: 'new',
    isChecked: false,
  },
  {
    id: 'completed',
    title: 'Completed',
    value: 'completed',
    isChecked: false,
  },
  {
    id: 'inprogress',
    title: 'Commited',
    value: 'inprogress',
    isChecked: false,
  },
  {
    id: 'draft',
    title: 'Draft',
    value: 'draft',
    isChecked: false,
  },
];

export const nfStatusFilters = [
  {
    title: 'Request drafted',
    value: 'draft',
    id: 'draft',
    isChecked: false,
  },
  {
    title: 'Request sent',
    value: 'new',
    id: 'new',
    isChecked: false,
  },
  {
    title: 'Commitment submitted',
    value: 'committed',
    id: 'committed',
    isChecked: false,
  },
  {
    title: 'Deliverable provided',
    value: 'provided',
    id: 'provided',
    isChecked: false,
  },
  {
    title: 'Deliverable accepted',
    value: 'accepted',
    id: 'accepted',
    isChecked: false,
  },
  {
    title: 'Request re-opened',
    value: 'reopened',
    id: 'reopened',
    isChecked: false,
  },
];
