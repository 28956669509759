import React, { useEffect, useRef, useState } from 'react';
import styles from './Input.module.scss';

interface IInput {
  error?: string;
  placeholder?: string;
  type: string;
  value: string;
  handleChange?: (value: string) => void;
  handleUnselect?: () => void;
  onFocus?: (name: string) => void;
  name?: string;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  hideErrorBlock?: boolean;
  // Auto focus on initialization
  autoFocus?: boolean;
  tabIndex?: number;
  ariaLabel?: string;
}

const InputBlock = ({
  error,
  placeholder,
  handleChange,
  value,
  handleUnselect,
  onFocus,
  disabled,
  className,
  inputClassName,
  name,
  autoFocus,
  tabIndex,
  ariaLabel,
}: IInput) => {
  const [isFocus, setFocus] = useState(false);

  const ref = useRef<any>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, []);

  const onInputFocus = (e: any) => {
    setFocus(true);
    onFocus && onFocus(e.target.name);
  };

  const onBlur = () => {
    setFocus(false);
    handleUnselect && handleUnselect();
  };
  return (
    <div
      className={`
        ${styles.input_wrapper} 
        ${className ? className : ''}
        ${error ? styles.error : ''}
        `}
    >
      <div
        className={`${styles.input_placeholder_block} ${error ? styles.input_wrapper_error : ''} ${
          isFocus ? styles.input_label_focus : ''
        }`}
        data-content={(value || '').length > 0 ? placeholder : ''}
      >
        <label htmlFor={`input-${name}`}>{placeholder}</label>
        <input
          id={`input-${name}`}
          className={`
                ${styles.input}
                ${disabled ? styles.input_disable : ''}
                ${isFocus ? styles.input_focus : ''}
                ${inputClassName || ''}
                `}
          value={value}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          onFocus={onInputFocus}
          onChange={e => {
            handleChange && handleChange(e.target.value);
          }}
          ref={ref}
          tabIndex={tabIndex}
          aria-label={ariaLabel || placeholder}
        />
      </div>
    </div>
  );
};

export default InputBlock;
