import React, { useState } from 'react';
import styles from './Calendar.module.scss';
import Calendar from 'react-calendar';
import { Popover } from '@mui/material';
import dayjs from 'dayjs';
import CalendarIc from '../../../assets/icons/Calendar icon.svg';
import SVG from 'react-inlinesvg';
import InputMask from 'react-input-mask';

interface IProps {
  className?: string;
  value: string | null;
  maxDate?: string;
  minDate?: string;
  name: string;
  placeholder: string;
  onChange: (name: string, value: string) => void;
  error?: any;
  handleChangeInput?: (value: string) => void;
}

const CalendarBlock = ({ className, value, maxDate, minDate, name, placeholder, onChange, error, handleChangeInput }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  const handleSelectedDate = date => {
    onChange(name, dayjs(date).format('MM/DD/YYYY'));
    closeCalendar();
  };

  const handleOpenCalendar = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeCalendar = () => {
    setAnchorEl(null);
  };

  const onChangeInput = e => {
    e.stopPropagation();
    handleChangeInput(e.target.value);
  };

  return (
    <div className={`${styles.container} ${error ? styles.error : ''} ${className ? className : ''}`}>
      <div className={styles.calendar}>
        <div>
          <div className={styles.title}>{placeholder || 'Date'}</div>
          <div className={styles.text}>
            <InputMask mask="99/99/9999" value={value} onChange={onChangeInput}>
              {inputProps => (
                <input
                  {...inputProps}
                  type="text"
                  className="input"
                  // disableUnderline
                />
              )}
            </InputMask>
            {/*{value && dayjs(new Date(value)).format('MM/DD/YYYY') || 'mm/dd/yyyy'}*/}
          </div>
        </div>
        <button onClick={handleOpenCalendar} className={styles.icon}>
          <SVG src={CalendarIc} />
        </button>
      </div>
      <Popover
        id={`calendar-${name}`}
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={closeCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            overflow: 'visible',
          },
        }}
      >
        <Calendar
          onChange={handleSelectedDate}
          calendarType={'US'}
          value={new Date(value.split('_').join('').length >= 10 ? value : 0 || new Date())}
          locale={'en'}
          // maxDate={maxDate ? new Date(maxDate) : null}
          // minDate={minDate ? new Date(minDate) : null}
        />
      </Popover>
    </div>
  );
};

export default CalendarBlock;
