import { call, all, take, StrictEffect, put, takeLatest } from 'redux-saga/effects';
import {
  getKpiData,
  getKpiDataSuccess,
  getFilters,
  getFiltersSuccess,
  getFiltersPCD,
  getFiltersPCDSuccess,
  setFiltersPCD,
  setFiltersPCDSuccess,
  saveSelectedGrouping,
  saveSelectedFilters,
  getMainMf,
  getMainMfSuccess,
  getMfFromParent,
  getMfFromParentSuccess,
  getMainUf,
  getMainUfSuccess,
  getUfFromParent,
  getUfFromParentSuccess,
  searchMForUFCode,
  searchMForUFCodeSuccess,
  createSchedule,
  createScheduleSuccess,
  updateSchedule,
  updateScheduleSuccess,
  deleteSchedule,
  deleteScheduleSuccess,
  createStone,
  createStoneSuccess,
  updateStone,
  updateStoneSuccess,
  deleteStone,
  deleteStoneSuccess,
  createMarker,
  createMarkerSuccess,
  updateMarker,
  updateMarkerSuccess,
  deleteMarker,
  deleteMarkerSuccess,
  createFlowLine,
  createFlowLineSuccess,
  updateFlowLine,
  updateFlowLineSuccess,
  deleteFlowLine,
  deleteFlowLineSuccess,
  createBox,
  createBoxSuccess,
  updateBox,
  updateBoxSuccess,
  deleteBox,
  deleteBoxSuccess,
  getKpiNfsData,
  getKpiNfsDataSuccess,
} from './kpiDataSlice';

import Api from '../../services/api';
import {
  KpiDataPayloadModel,
  StonePayloadModel,
  MarkerPayloadModel,
  FlowLinesPayloadModel,
  SchedulePayloadModel,
  FlowLinesDeleteModel,
  MarkerDeleteModel,
  StoneDeleteModel,
  ScheduleDeleteModel,
  BoxDeleteModel,
  BoxPayloadModel,
} from '../../models';
import { PayloadDataModel, PayloadModel } from '../../models/global';
import {
  FiltersPayloadModel,
  FiltersPCDPayloadModel,
  MainCodesPayloadModel,
  MfCodesParentPayloadModel,
  SearchCodePayloadModel,
  UfCodesParentPayloadModel,
} from '../../models/filters/filters.model';

function* getKpiDataSaga({ payload }): Generator<StrictEffect, void, any> {
  const { project_id, building_id, callback }: KpiDataPayloadModel = payload;

  try {
    const response = yield call(Api.post, `kpi-data/${project_id}/${building_id}`);
    if (response) {
      yield put(getKpiDataSuccess(response.data.resource));

      if (callback) {
        callback(response.data.resource, null);
      }
    }
  } catch (e: any) {
    callback(null, e);
    console.log('KpiDataError', e.response);
    // if (e.response.status === 400) {
    //   callback(e.response.data.errors);
    // }
  }
}

function* getKpiNfsDataSaga({ payload }): Generator<StrictEffect, void, any> {
  const { project_id, building_id, filters, callback }: KpiDataPayloadModel = payload;

  try {
    const response = yield call(Api.post, `kpi-nf-data-v2/${project_id}/${building_id}`, { filters });

    if (!response.status) throw response;
    yield put(getKpiNfsDataSuccess({ ...response.data.resource, project_id }));

    if (callback) {
      callback(response.data.resource, null);
    }
  } catch (e: any) {
    callback(null, e);
    console.log('KpiDataError', e.response);
    // if (e.response.status === 400) {
    //   callback(e.response.data.errors);
    // }
  }
}

function* getFiltersSaga({ payload }): Generator<StrictEffect, void, any> {
  const { data, callback }: PayloadDataModel<FiltersPayloadModel, null> = payload;

  try {
    const response = yield call(Api.post, `request/filters`, data);
    if (response) {
      yield put(getFiltersSuccess(response.data.resource));
      if (callback) {
        // callback(response.data.resource, null);
      }
    }
  } catch (e: any) {
    console.log('KpiDataError', e.response);
    // if (e.response.status === 400) {
    //   callback(e.response.data.errors);
    // }
  }
}
function* getFiltersPCDSaga(action): Generator<StrictEffect, void, any> {
  const payload: FiltersPCDPayloadModel = action.payload;

  try {
    const response = yield call(Api.post, `pcd/${payload.project_id}/filters`, { building_id: payload.building_id });
    if (response) {
      yield put(getFiltersPCDSuccess(response.data.resource));
    }
  } catch (e: any) {
    console.log('KpiDataError', e.response);
    // if (e.response.status === 400) {
    //   callback(e.response.data.errors);
    // }
  }
}
function* setFiltersPCDSaga({ payload }): Generator<StrictEffect, void, any> {
  const { building_id, callback, filters, project_id } = payload;

  try {
    const response = yield call(Api.post, `kpi-pcd-data/${project_id}/${building_id}`, { filters });
    if (response) {
      yield put(setFiltersPCDSuccess(response.data.resource));
      if (callback) {
        callback();
      }
    }
  } catch (e: any) {
    console.log('KpiDataError', e.response);
    // if (e.response.status === 400) {
    //   callback(e.response.data.errors);
    // }
  }
}

function* getMainMfSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<MainCodesPayloadModel> = yield take(getMainMf.toString());

    try {
      const response = yield call(Api.post, `needlist/filters-mf-main`, data);
      if (response) {
        yield put(getMainMfSuccess(response.data));
        if (callback) {
          // callback(response.data.resource, null);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getMfFromParentSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<MfCodesParentPayloadModel> = yield take(getMfFromParent.toString());

    try {
      const response = yield call(Api.post, `needlist/filters-mf-parent`, data);
      if (response) {
        yield put(getMfFromParentSuccess(response.data));
        if (callback) {
          // callback(response.data.resource, null);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getMainUfSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<MainCodesPayloadModel> = yield take(getMainUf.toString());

    try {
      const response = yield call(Api.post, `needlist/filters-uf-main`, data);
      if (response) {
        yield put(getMainUfSuccess(response.data));
        if (callback) {
          // callback(response.data.resource, null);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getUfFromParentSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<UfCodesParentPayloadModel> = yield take(getUfFromParent.toString());

    try {
      const response = yield call(Api.post, `needlist/filters-uf-parent`, data);
      if (response) {
        yield put(getUfFromParentSuccess(response.data));
        if (callback) {
          // callback(response.data.resource, null);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* searchMForUFCodeSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<SearchCodePayloadModel> = yield take(searchMForUFCode.toString());

    try {
      const response = yield call(Api.post, `needlist/filters-mf-uf-search`, data);
      if (response) {
        yield put(searchMForUFCodeSuccess({ data: response.data.search, table: data.table }));
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* createScheduleSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<SchedulePayloadModel, null> = yield take(createSchedule.toString());

    try {
      const response = yield call(Api.post, `kpi-schedule/create`, data);
      if (response) {
        yield put(createScheduleSuccess(response.data.resource));
        if (callback) {
          callback(response.data.resource);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* updateScheduleSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<SchedulePayloadModel, null> = yield take(updateSchedule.toString());

    try {
      const response = yield call(Api.put, `kpi-schedule/update`, data);
      if (response) {
        yield put(updateScheduleSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* deleteScheduleSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<ScheduleDeleteModel, null> = yield take(deleteSchedule.toString());

    try {
      const response = yield call(Api.delete, `kpi-schedule/delete`, data);
      if (response) {
        yield put(deleteScheduleSuccess(data.id));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* createStoneSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<StonePayloadModel, null> = yield take(createStone.toString());

    try {
      const response = yield call(Api.post, `kpi-stone/create`, data);
      if (response) {
        yield put(createStoneSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* updateStoneSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<StonePayloadModel, null> = yield take(updateStone.toString());

    try {
      const response = yield call(Api.put, `kpi-stone/update`, data);
      if (response) {
        yield put(updateStoneSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* deleteStoneSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<StoneDeleteModel, null> = yield take(deleteStone.toString());

    try {
      const response = yield call(Api.delete, `kpi-stone/delete`, data);
      if (response) {
        yield put(deleteStoneSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* createMarkerSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<MarkerPayloadModel, null> = yield take(createMarker.toString());

    try {
      const response = yield call(Api.post, `kpi-marker/create`, data);
      if (response) {
        yield put(createMarkerSuccess(response.data.resource));
        if (callback) {
          callback(response.data.resource);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* updateMarkerSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<MarkerPayloadModel, null> = yield take(updateMarker.toString());

    try {
      const response = yield call(Api.put, `kpi-marker/update`, data);
      if (response) {
        yield put(updateMarkerSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* deleteMarkerSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<MarkerDeleteModel, null> = yield take(deleteMarker.toString());

    try {
      const response = yield call(Api.delete, `kpi-marker/delete`, data);
      if (response) {
        yield put(deleteMarkerSuccess(data.id));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* createFlowLineSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<FlowLinesPayloadModel, null> = yield take(createFlowLine.toString());

    try {
      const response = yield call(Api.post, `kpi-flow-line/create`, data);
      if (response) {
        yield put(createFlowLineSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* updateFlowLineSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<FlowLinesPayloadModel, null> = yield take(updateFlowLine.toString());

    try {
      const response = yield call(Api.put, `kpi-flow-line/update`, data);
      if (response) {
        yield put(updateFlowLineSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* deleteFlowLineSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<FlowLinesDeleteModel, null> = yield take(deleteFlowLine.toString());

    try {
      const response = yield call(Api.delete, `kpi-flow-line/delete`, data);
      if (response) {
        yield put(deleteFlowLineSuccess(data.id));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* createBoxSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<BoxPayloadModel, null> = yield take(createBox.toString());

    try {
      const response = yield call(Api.post, `kpi-box/create`, data);
      if (response) {
        yield put(createBoxSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* updateBoxSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<BoxPayloadModel, null> = yield take(updateBox.toString());

    try {
      const response = yield call(Api.put, `kpi-box/update`, data);
      if (response) {
        yield put(updateBoxSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* deleteBoxSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<BoxDeleteModel, null> = yield take(deleteBox.toString());

    try {
      const response = yield call(Api.delete, `kpi-box/delete`, data);
      if (response) {
        yield put(deleteBoxSuccess(response.data.resource));
        if (callback) {
          callback();
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

export function* saga() {
  yield all([
    takeLatest(getKpiData, getKpiDataSaga),
    takeLatest(getKpiNfsData, getKpiNfsDataSaga),
    takeLatest(setFiltersPCD, setFiltersPCDSaga),
    createScheduleSaga(),
    updateScheduleSaga(),
    deleteScheduleSaga(),
    createStoneSaga(),
    updateStoneSaga(),
    deleteStoneSaga(),
    createMarkerSaga(),
    updateMarkerSaga(),
    deleteMarkerSaga(),
    createFlowLineSaga(),
    updateFlowLineSaga(),
    deleteFlowLineSaga(),
    createBoxSaga(),
    updateBoxSaga(),
    deleteBoxSaga(),
    takeLatest(getFilters, getFiltersSaga),
    takeLatest(getFiltersPCD, getFiltersPCDSaga),
    getMainMfSaga(),
    getMfFromParentSaga(),
    getMainUfSaga(),
    getUfFromParentSaga(),
    searchMForUFCodeSaga(),
  ]);
}
