import React, { useEffect, useRef, useState } from 'react';
import styles from './Select.module.scss';
import { List, ListItem, Popover } from '@mui/material';
import SelectIc from '../../../assets/icons/floor.svg';
import SVG from 'react-inlinesvg';
import { ISelectOptions } from '../../../models/global';

interface IProps {
  name: string;
  placeholder: string;
  options: ISelectOptions[];
  value: any;
  onChange?: (name: string, ISelectOptions: any) => void;
  className?: string;
  // path to image
  customIc?: string;
  disabled?: boolean;
  error?: any;
}

const Select = ({ className, name, onChange, options, placeholder, value, customIc, disabled, error }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const handleSelect = (data: ISelectOptions) => {
    onChange(name, data);
    closeSelect();
  };

  const handleOpenSelect = e => {
    if (disabled) {
      return;
    }
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const closeSelect = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`${styles.container} ${error ? styles.error : ''} ${disabled ? styles.disabled : ''} ${className ? className : ''}`}>
      <button className={styles.select} onClick={handleOpenSelect}>
        <div>
          <div className={styles.title}>{placeholder || 'Select'}</div>
          <div className={styles.text}>{(options.length && options.find(o => o.value === value)?.label) || ''}</div>
        </div>
        <div className={styles.icon}>
          <SVG src={customIc || SelectIc} />
        </div>
      </button>
      <Popover
        id={`calendar-${name}`}
        open={anchorEl}
        anchorEl={anchorEl}
        onClose={closeSelect}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            overflow: 'auto',
            maxHeight: '360px',
          },
        }}
      >
        <div
          style={{
            width: anchorEl?.clientWidth || 'auto',
          }}
          className={styles.options}
        ></div>
        {options.map((option, i) => {
          return (
            <button
              ref={r => {
                if (i === 0) {
                  r && r.focus();
                }
              }}
              key={`select-${option.value}`}
              className={styles.item}
              onClick={() => handleSelect(option)}
            >
              <span>{option.label}</span>
            </button>
          );
        })}
      </Popover>
    </div>
  );
};

// <div
//     key={`select-${option.value}`}
//     className={styles.item}
//     onClick={() => handleSelect(option)}
// >
//     <span>{option.label}</span>
// </div>
export default Select;
