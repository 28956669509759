import {
  FlowLinesFormModel,
  FlowLinesModel,
  KpiDataReducerModel,
  LocationDataShortenedModel,
  LocationFormModel,
  LocationModel,
  LocationsNfsDataModel,
  MarkerModel,
} from '../models';
import { ISelectOptions } from '../models/global';

export class ConverterHelper {
  public static flowLineToForm = (flowLine: FlowLinesModel): FlowLinesFormModel => {
    return {
      id: flowLine.id,
      endDate: flowLine.end_date,
      endFloor: flowLine.end_level_id,
      title: flowLine.title,
      startDate: flowLine.start_date,
      startFloor: flowLine.start_level_id,
      color: flowLine.color,
      interpolation: flowLine.interpolation,
      mode: flowLine.interpolation_type,
      manual_interpolation_type: flowLine.manual_interpolation_type,
      is_active: flowLine.is_active,
      data: flowLine.data.map(el => {
        return {
          ...el,
          data: el.data.map(fl => {
            return {
              ...fl,
              startDate: fl.startDate ? new Date(fl.startDate) : '',
              endDate: fl.endDate ? new Date(fl.endDate) : '',
              isActive: fl.isActive || !!(fl.startDate || fl.endDate),
            };
          }),
        };
      }),
    };
  };

  public static shortenedLvlToForm = (lvlData: LocationsNfsDataModel, index: number, kpiData: KpiDataReducerModel): LocationFormModel => {
    return {
      aboveGround: !lvlData.below_grade,
      index: index,
      name: lvlData.title,
      prefix: '',
      suffix: null,
      relatedToLevelId: null,
      description: '',
      id: lvlData.id,
      data: lvlData.nf_data
        ? lvlData.nf_data.map((l: any) => {
            const reqNfDiscipline = kpiData.disciplines.find(discipline => discipline.id === l.reqD);
            const resNfDiscipline = kpiData.disciplines.find(discipline => discipline.id === l.resD);
            const reqNfCompany = kpiData.parties.find(company => company.id === l.reqC);
            const resNfCompany = kpiData.parties.find(company => company.id === l.resC);
            const nfMilestone = kpiData.milestones.find(milestone => milestone.id === l.mil);
            const nfReqType = kpiData.request_types.find(reqType => reqType.id === l.type);

            const nfBuildingSpacesIds = l.bs ? l.bs?.split(',').map(bsId => Number(bsId)) : [];
            const nfBuildingSpacesTitles = kpiData.building_spaces.reduce((acc, buildingSpace) => {
              if (nfBuildingSpacesIds.includes(Number(buildingSpace.id))) {
                acc.push(buildingSpace.title);
              }
              return acc;
            }, []);

            return {
              completedDate: l.com,
              created: l.cre,
              dueDate: l.due,
              decl: l.decl,
              geometryRelated: true, //skip
              groupType: 'no group', // skip
              mf: l.mf || '',
              nfStatus: l.st, // Need change to correct name
              nf_id: l.nf,
              id: l.id,
              reqAuthorCompany: '', //
              reqDiscipline: reqNfDiscipline?.title || '',
              reqColor: reqNfDiscipline?.color || '',
              reqCompany: reqNfCompany?.company || '',
              resCompany: resNfCompany?.company || '',
              resDiscipline: resNfDiscipline?.title || '',
              resColor: resNfDiscipline?.color || '',
              reqType: nfReqType?.title || '',
              uf: l.uf || '',
              milestone: nfMilestone?.title || '',
              building_spaces: nfBuildingSpacesTitles?.join() || '',
              is_whiteboard: l.wb,
              level_id: lvlData.id,
              show: l.show,
            };
          })
        : [],
      deliverables: [],
    };
  };

  public static levelToForm = (lvl: LocationModel, index: number): LocationFormModel => {
    return {
      aboveGround: !lvl.below_grade,
      index: index,
      name: lvl.title,
      prefix: '',
      suffix: null,
      relatedToLevelId: null,
      description: '',
      id: lvl.id,
      data: [],
      deliverables: [],
    };
  };

  public static levelsToOptions = (lvls: LocationFormModel[]): ISelectOptions[] => {
    return lvls.map(lvl => {
      return {
        label: lvl.name,
        value: lvl.id,
      };
    });
  };

  public static markersToForm = (marker: MarkerModel): MarkerModel => {
    const convertedDate = `${marker.date} +00:00`;
    return {
      ...marker,
      date: new Date(convertedDate),
    };
  };
}
