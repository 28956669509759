const colorType = {
  type: {
    '': '#AAAAAA',
    RFI: '#5654AE',
    'Design constraint': '#DF4141',
    'CL input checklist': '#EF8C54',
    'Standard request': '#9DB3F4',
    'Private RFI': '#5654AE',
    'CL drawing': '#6AA071',
    'Submittals/cut sheets': '#F2B55A',
    'For record': '#CD71D4',
  },
  kpi: {
    PNF: '#80b1d3',
    PDC: '#407193',
    SNF: '#95FFC7',
    SDC: '#00FFAB',
    SRFI: '#00A34E',
    GNF: '#FFE691',
    GDC: '#FDB462',
    GRFI: '#FF8D3B',
    'no group': '#AAAAAA',
  },
  uf: {
    '': '#AAAAAA',
    A: '#9DB3F4',
    A10: '#9DB3F4',
    A20: '#9DB3F4',
    A40: '#9DB3F4',
    A60: '#9DB3F4',
    A90: '#9DB3F4',
    B: '#5654AE',
    B10: '#5654AE',
    B20: '#5654AE',
    B30: '#5654AE',
    C: '#EF8C54',
    C10: '#EF8C54',
    C20: '#EF8C54',
    D: '#CD71D4',
    D10: '#CD71D4',
    D20: '#CD71D4',
    D30: '#CD71D4',
    D40: '#CD71D4',
    D50: '#CD71D4',
    D60: '#CD71D4',
    D70: '#CD71D4',
    D80: '#CD71D4',
    E: '#F2B55A',
    E10: '#F2B55A',
    E20: '#F2B55A',
    F: '#DF4141',
    F10: '#DF4141',
    F20: '#DF4141',
    F30: '#DF4141',
    G: '#6AA071',
    G10: '#6AA071',
    G20: '#6AA071',
    G30: '#6AA071',
    G40: '#6AA071',
    G50: '#6AA071',
    G90: '#6AA071',
    Z: '#B6B77F',
    Z10: '#B6B77F',
    Z70: '#B6B77F',
    Z90: '#B6B77F',
    Z99: '#B6B77F',
  },
  combine: {
    all: '#AAAAAA',
  },
  default: [
    '#8F16C0',
    '#EBCE2B',
    '#96CDE6',
    '#FF6B00',
    '#BA1C30',
    '#C0BD7F',
    '#5FA641',
    '#D485B2',
    '#4076B4',
    '#DF8461',
    '#E0A11B',
    '#92AE31',
    '#A52481',
    '#F22515',
  ],
  'nf-status': {
    sent: '#9DB3F4',
    submitted: '#EF8C54',
    provided: '#F2B55A',
    accepted: '#6AA071',
    declined: '#DF4141',
  },
  'no-group': {
    '': '#AAAAAA',
  },
};

export { colorType };
