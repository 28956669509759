import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';

import kpiData from './kpiData/kpiDataSlice';
import authorization from './authData/authoriztionSlice';
import clientsProjectsReducer from './clientProjectsData/clientProjectsSlice';

export const combinedReducers = combineReducers({
  kpiData,
  authorization,
  clientsProjectsReducer,
});

export type RootState = ReturnType<typeof combinedReducers>;

export const rootReducer: Reducer = (state: RootState, action: AnyAction): RootState => {
  return combinedReducers(state, action);
};
