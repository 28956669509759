import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import {
  BoxDeleteModel,
  BoxPayloadModel,
  FlowLinesDeleteModel,
  FlowLinesFormModel,
  FlowLinesModel,
  FlowLinesPayloadModel,
  InterpolationModeType,
  InterpolationType,
  KeyValueModel,
  KpiDataModel,
  KpiDataPayloadModel,
  KpiDataReducerModel,
  KpiLocationsNfDataModel,
  KpiNfsDataPayloadModel,
  LocationDataModel,
  LocationFormModel,
  LocationModel,
  MarkerDeleteModel,
  MarkerModel,
  MarkerPayloadModel,
  MfTreeModel,
  ScheduleBoxModel,
  ScheduleDeleteModel,
  ScheduleModel,
  SchedulePayloadModel,
  ScheduleStoneModel,
  StoneDeleteModel,
  StonePayloadModel,
  UfMfValueModel,
  UfTreeModel,
} from '../../models';
import { PayloadModel, RequestStatusModel } from '../../models/global';
import { ConverterHelper } from '../../helpers';
import {
  FiltersModel,
  FiltersPayloadModel,
  FiltersPCDPayloadModel,
  MainCodesPayloadModel,
  MfCodesParentPayloadModel,
  SaveSelectedMfOptionsPayloadModel,
  SaveSelectedUfOptionsPayloadModel,
  SearchCodePayloadModel,
  UfCodesParentPayloadModel,
} from '../../models/filters/filters.model';
import Api from '../../services/api';

export interface InitialState {
  kpiData: KpiDataReducerModel | null;
  kpiDataLoading: RequestStatusModel;
  filters: KeyValueModel<FiltersModel[]> | null;
  filtersLoading: RequestStatusModel;
  filtersPCD: KeyValueModel<FiltersModel[]> | null;
  filtersPCDLoading: RequestStatusModel;
  savedSelectedFilters: string[] | null;
  savedSelectedFilterOptions: KeyValueModel<number[] | string[]> | null;
  savedSelectedPCDFilters: string[] | null;
  savedSelectedPCDFilterOptions: KeyValueModel<number[] | string[]> | null;
  savedSelectedUfOptions: any;
  savedSelectedMfOptions: any;
  savedSelectedGrouping: string | null;
  savedSelectedGroups: any | null;
  savedProjectName: string | null;
  savedBuildingId: number | null;
  prevBuildingId: number;
  ufCodes: UfMfValueModel[] | null;
  mfCodes: UfMfValueModel[] | null;
  ufTree: UfTreeModel | null;
  mfTree: MfTreeModel | null;
  activeMarker: null | { color: string; date: string };
  toolbarActiveComponent: null | string;
  activeFlowLine: null | FlowLinesFormModel;
  isKpiGraphHidden: boolean;
  isKpiGraphResetZoom: boolean;
  zoomToCurrent: boolean;
  selectedFL: FlowLinesFormModel | null;
  selectedSchedule: ScheduleModel | null;
}

const initialState: InitialState = {
  kpiData: null,
  kpiDataLoading: Api.initialStatus,
  filters: null,
  filtersLoading: Api.initialStatus,
  filtersPCD: null,
  filtersPCDLoading: Api.initialStatus,
  savedSelectedFilters: JSON.parse(localStorage.getItem('savedSelectedFilters')) || [],
  savedSelectedFilterOptions: JSON.parse(localStorage.getItem('savedSelectedFilterOptions')) || {},
  savedSelectedPCDFilters: JSON.parse(localStorage.getItem('savedSelectedPCDFilters')) || [],
  savedSelectedPCDFilterOptions: JSON.parse(localStorage.getItem('savedSelectedPCDFilterOptions')) || {},
  savedSelectedUfOptions: JSON.parse(localStorage.getItem('savedSelectedUfOptions')) || [],
  savedSelectedMfOptions: JSON.parse(localStorage.getItem('savedSelectedMfOptions')) || [],
  savedSelectedGrouping: JSON.parse(localStorage.getItem('savedSelectedGrouping')) || 'type',
  savedSelectedGroups: JSON.parse(localStorage.getItem('savedSelectedGroups')) || null,
  savedProjectName: JSON.parse(localStorage.getItem('savedProjectName')) || null,
  savedBuildingId: null,
  prevBuildingId: 0,
  ufCodes: null,
  mfCodes: null,
  ufTree: null,
  mfTree: null,
  activeMarker: null,
  toolbarActiveComponent: null,
  activeFlowLine: null,
  isKpiGraphHidden: false,
  isKpiGraphResetZoom: false,
  zoomToCurrent: false,
  selectedFL: null,
  selectedSchedule: null,
};

const kpiData = createSlice({
  name: 'kpiData',
  initialState,
  reducers: {
    setKpiGraphResetZoom: (state, { payload }: PayloadAction<boolean>) => {
      state.isKpiGraphResetZoom = payload;
    },
    setKpiGraphHidden: (state, { payload }: PayloadAction<boolean>) => {
      state.isKpiGraphHidden = payload;
    },
    setZoomToCurrent: (state, { payload }: PayloadAction<boolean>) => {
      state.zoomToCurrent = payload;
    },
    getKpiData: (state, { payload }: PayloadAction<KpiDataPayloadModel>) => {
      const currentState = current(state);
      state.kpiData = null;
      state.kpiDataLoading = Api.requestStatus;
    },
    getKpiDataSuccess: (state, { payload }: PayloadAction<KpiDataModel>) => {
      const flow_lines: FlowLinesFormModel[] = [...(payload.flow_lines || [])].map(line => {
        return ConverterHelper.flowLineToForm(line);
      });
      const markers: MarkerModel[] = [...(payload.markers || [])].map(marker => {
        return ConverterHelper.markersToForm(marker);
      });

      state.kpiData = {
        ...payload,
        locations: [],
        locationsForCurrentLevel: payload.locations,
        flow_lines: flow_lines,
        markers: markers,
        scheduleDateRange: {
          startDate: payload.graph_start,
          endDate: payload.graph_end,
        },
        building_spaces: payload.building_spaces,
        milestones: payload.milestones,
        parties: payload.parties,
        request_types: payload.request_types,
        disciplines: payload.disciplines,
        deliverableLevels: payload.locations,
      };
    },
    getKpiNfsData: (state, { payload }: PayloadAction<KpiNfsDataPayloadModel>) => {
      localStorage.setItem('savedSelectedFilterOptions', JSON.stringify(payload.selectedFilterOptions));
      localStorage.setItem('savedProjectName', JSON.stringify(payload.projectName));
      localStorage.setItem('savedBuildingId', JSON.stringify(payload.building_id));
      localStorage.setItem('prevBuildingId', JSON.stringify(state.savedBuildingId || 0));
      state.savedSelectedFilterOptions = payload.selectedFilterOptions;
      state.savedProjectName = payload.projectName;
      state.savedBuildingId = payload.building_id;
    },
    getKpiNfsDataSuccess: (state, { payload }: PayloadAction<KpiLocationsNfDataModel>) => {
      state.kpiDataLoading = Api.successStatus;

      if (!payload.locations && !payload.no_location) {
        return state;
      }

      const currentState = current(state);

      const noLocationData = {
        id: 0,
        project_id: payload.project_id,
        building_id: 0,
        title: 'No levels assigned',
        below_grade: false,
        position: 0,
        nf_data: payload.no_location || [],
      };

      const levels = [...(payload.locations || [])].sort((a, b) => b.position - a.position);
      const locationNfsData = payload.no_location ? [...levels, noLocationData] : levels;

      const kpiLevels: LocationFormModel[] = locationNfsData.map((lvl, index) => {
        return ConverterHelper.shortenedLvlToForm(lvl, index, currentState.kpiData);
      });

      state.kpiData.locations = kpiLevels;
      state.isKpiGraphHidden = false;
      state.zoomToCurrent = +localStorage.getItem('prevBuildingId') !== +localStorage.getItem('savedBuildingId');
    },
    getFilters: (state, { payload }: PayloadModel<FiltersPayloadModel, null>) => {
      state.filters = null;
      state.filtersLoading = Api.requestStatus;
    },
    getFiltersSuccess: (state, { payload }: PayloadAction<KeyValueModel<FiltersModel[]>>) => {
      state.filters = payload;
      state.filtersLoading = Api.successStatus;
    },
    getFiltersPCD: (state, { payload }: PayloadAction<any>) => {
      state.filtersPCD = null;
      state.filtersPCDLoading = Api.requestStatus;
    },
    getFiltersPCDSuccess: (state, { payload }: PayloadAction<KeyValueModel<FiltersModel[]>>) => {
      const pcdFiltersHelper = (key: string): boolean => {
        switch (key) {
          case 'card_status':
            return true;
          case 'review_state':
            return true;
          case 'deliverable_type':
            return true;
          case 'deliverable_discipline':
            return true;
          case 'responsible_party':
            return true;
          case 'responsible_party_manager':
            return true;
          case 'review_company':
            return true;
          case 'review_manager':
            return true;
          case 'deliverable_name':
            return true;
          default:
            return false;
        }
      };
      const filtersPCDData = Object.keys(payload)
        .filter(key => pcdFiltersHelper(key))
        .reduce((cur, key) => {
          return Object.assign(cur, { [key]: payload[key] });
        }, {});

      state.filtersPCD = filtersPCDData;
      state.filtersPCDLoading = Api.successStatus;
    },
    setFiltersPCD: (state, { payload }: PayloadAction<any>) => {
      state.kpiDataLoading = Api.requestStatus;
    },
    setFiltersPCDSuccess: (state, { payload }: PayloadAction<any>) => {
      state.kpiData.deliverableLevels = payload.locations;

      payload.locations.reduce((_, delLvl) => {
        state.kpiData.locations &&
          state.kpiData.locations.map(lvl => {
            if (delLvl.title === lvl.name) {
              return (lvl.deliverables = delLvl.deliverables);
            }
          });

        return _;
      }, null);

      state.kpiDataLoading = Api.successStatus;
      state.isKpiGraphHidden = false;
    },
    saveSelectedGrouping: (state, { payload }: PayloadAction<string, null>) => {
      localStorage.setItem('savedSelectedGrouping', JSON.stringify(payload));
      state.savedSelectedGrouping = payload;
    },
    saveSelectedGroups: (state, { payload }: PayloadAction<any, null>) => {
      localStorage.setItem('savedSelectedGroups', JSON.stringify(payload));
      state.savedSelectedGroups = payload;
    },
    saveSelectedFilters: (state, { payload }: PayloadAction<string[], null>) => {
      localStorage.setItem('savedSelectedFilters', JSON.stringify(payload));
      state.savedSelectedFilters = payload;
    },
    saveSelectedPCDFilters: (state, { payload }: PayloadAction<string[], null>) => {
      localStorage.setItem('savedSelectedPCDFilters', JSON.stringify(payload));
      state.savedSelectedPCDFilters = payload;
    },
    saveSelectedPCDFilterOptions: (state, { payload }: PayloadAction<KeyValueModel<number[] | string[]>, null>) => {
      localStorage.setItem('savedSelectedPCDFilterOptions', JSON.stringify(payload));

      state.savedSelectedPCDFilterOptions = payload;
    },
    saveSelectedUfOptions: (state, { payload }: PayloadAction<SaveSelectedUfOptionsPayloadModel, null>) => {
      localStorage.setItem('savedSelectedUfOptions', JSON.stringify(payload.selectedUfOptions));
      state.savedSelectedUfOptions = payload.selectedUfOptions;
    },
    saveSelectedMfOptions: (state, { payload }: PayloadAction<SaveSelectedMfOptionsPayloadModel, null>) => {
      localStorage.setItem('savedSelectedMfOptions', JSON.stringify(payload.selectedMfOptions));
      state.savedSelectedMfOptions = payload.selectedMfOptions;
    },
    getMainMf: (state, { payload }) => {},
    getMainMfSuccess: (state, { payload }) => {
      state.mfCodes = payload.mainMfc;
    },
    getMfFromParent: (state, { payload }) => {},
    getMfFromParentSuccess: (state, { payload }) => {
      state.mfTree = payload;
    },
    getMainUf: (state, { payload }) => {},
    getMainUfSuccess: (state, { payload }) => {
      state.ufCodes = payload.mainUfc;
    },
    getUfFromParent: (state, { payload }) => {},
    getUfFromParentSuccess: (state, { payload }) => {
      state.ufTree = payload;
    },
    searchMForUFCode: (state, { payload }) => {},
    searchMForUFCodeSuccess: (state, { payload }) => {
      if (payload.table === 'uf') {
        state.ufCodes = payload.data;
      } else {
        state.mfCodes = payload.data;
      }
    },
    createSchedule: (state, { payload }: PayloadModel<SchedulePayloadModel>) => {},
    createScheduleSuccess: (state, { payload }: PayloadAction<ScheduleModel>) => {
      if (state.kpiData) {
        state.kpiData.schedules.push(payload);
      }
    },
    updateSchedule: (state, { payload }: PayloadModel<SchedulePayloadModel>) => {},
    updateScheduleSuccess: (state, { payload }: PayloadAction<ScheduleModel>) => {
      const changeScheduleIndex = state.kpiData?.schedules.findIndex(schedule => schedule.id === payload.id);
      if (state.kpiData && changeScheduleIndex >= 0) {
        state.kpiData.schedules[changeScheduleIndex] = payload;
      }
    },
    deleteSchedule: (state, { payload }: PayloadModel<ScheduleDeleteModel>) => {},
    deleteScheduleSuccess: (state, { payload }: PayloadAction<number>) => {
      const deleteScheduleIndex = state.kpiData?.schedules.findIndex(schedule => schedule.id === payload);
      if (state.kpiData && deleteScheduleIndex > -1) {
        state.kpiData.schedules.splice(deleteScheduleIndex, 1);
      }
    },
    createStone: (state, { payload }: PayloadModel<StonePayloadModel>) => {},
    createStoneSuccess: (state, { payload }: PayloadAction<ScheduleStoneModel>) => {
      // if (state.kpiData && state.kpiData.schedules) {
      //     state.kpiData.schedules.stones.push(payload);
      // }
    },
    updateStone: (state, { payload }: PayloadModel<StonePayloadModel>) => {},
    updateStoneSuccess: (state, { payload }: PayloadAction<ScheduleStoneModel>) => {
      // const changeStoneIndex = state.kpiData?.schedules.stones?.findIndex(stone => stone.id === payload.id);
      // if (state.kpiData && state.kpiData.schedules && changeStoneIndex) {
      //     state.kpiData.schedules.stones[changeStoneIndex] = payload;
      // }
    },
    deleteStone: (state, { payload }: PayloadModel<StoneDeleteModel>) => {},
    deleteStoneSuccess: (state, { payload }: PayloadAction<ScheduleStoneModel>) => {
      // const deleteStoneIndex = state.kpiData?.schedules.findIndex(stone => stone.id === payload.id);
      // if (state.kpiData && state.kpiData.schedules && deleteStoneIndex) {
      //     state.kpiData.schedules.stones.splice(deleteStoneIndex,1);
      // }
    },
    createMarker: (state, { payload }: PayloadModel<MarkerPayloadModel>) => {},
    createMarkerSuccess: (state, { payload }: PayloadAction<MarkerModel>) => {
      if (state.kpiData) {
        state.kpiData.markers.push(ConverterHelper.markersToForm(payload));
      }
    },
    updateMarker: (state, { payload }: PayloadModel<MarkerPayloadModel>) => {},
    updateMarkerSuccess: (state, { payload }: PayloadAction<MarkerModel>) => {
      const changeMarkerIndex = state.kpiData?.markers.findIndex(marker => marker.id === payload.id);
      if (state.kpiData && changeMarkerIndex >= 0) {
        state.kpiData.markers[changeMarkerIndex] = payload;
      }
    },
    deleteMarker: (state, { payload }: PayloadModel<MarkerDeleteModel>) => {},
    deleteMarkerSuccess: (state, { payload }: PayloadAction<number>) => {
      const deleteMarkerIndex = state.kpiData?.markers.findIndex(marker => marker.id === payload);
      if (state.kpiData && deleteMarkerIndex > -1) {
        state.kpiData.markers.splice(deleteMarkerIndex, 1);
      }
    },
    setActiveMarker: (state, { payload }: { payload: { color: string; date: string } }) => {
      state.activeMarker = payload;
    },
    createFlowLine: (state, { payload }: PayloadModel<FlowLinesPayloadModel>) => {},
    createFlowLineSuccess: (state, { payload }: PayloadAction<FlowLinesModel>) => {
      if (state.kpiData) {
        state.kpiData.flow_lines.push(ConverterHelper.flowLineToForm(payload));
      }
    },
    updateFlowLine: (state, { payload }: PayloadModel<FlowLinesPayloadModel>) => {},
    updateFlowLineSuccess: (state, { payload }: PayloadAction<FlowLinesModel>) => {
      const changeFlowLineIndex = state.kpiData?.flow_lines.findIndex(flow_line => flow_line.id === payload.id);
      if (state.kpiData && changeFlowLineIndex > -1) {
        state.kpiData.flow_lines[changeFlowLineIndex] = ConverterHelper.flowLineToForm(payload);
      }
    },
    deleteFlowLine: (state, { payload }: PayloadModel<FlowLinesDeleteModel>) => {},
    deleteFlowLineSuccess: (state, { payload }: PayloadAction<number>) => {
      const deleteFlowLineIndex = state.kpiData?.flow_lines.findIndex(flow_line => flow_line.id === payload);
      if (state.kpiData && deleteFlowLineIndex > -1) {
        state.kpiData.flow_lines.splice(deleteFlowLineIndex, 1);
      }
    },
    setActiveFlowLine: (state, { payload }: { payload: FlowLinesFormModel }) => {
      state.activeFlowLine = payload;
    },
    setToolBarActiveComponent: (state, { payload }: PayloadAction<string>) => {
      state.toolbarActiveComponent = payload;
    },
    setSelectedFL: (state, { payload }: { payload: FlowLinesFormModel | null }) => {
      state.selectedFL = payload;
    },
    setSelectedSchedule: (state, { payload }: { payload: ScheduleModel | null }) => {
      state.selectedSchedule = payload;
    },
    createBox: (state, { payload }: PayloadAction<BoxPayloadModel>) => {},
    createBoxSuccess: (state, { payload }: PayloadAction<ScheduleBoxModel>) => {
      // if (state.kpiData && state.kpiData.schedules) {
      //     state.kpiData.schedules.boxes.push(payload);
      // }
    },
    updateBox: (state, { payload }: PayloadAction<BoxPayloadModel>) => {},
    updateBoxSuccess: (state, { payload }: PayloadAction<ScheduleBoxModel>) => {
      // const changeBoxIndex = state.kpiData?.schedules.boxes?.findIndex(box => box.id === payload.id);
      // if (state.kpiData && state.kpiData.schedules && changeBoxIndex) {
      //     state.kpiData.schedules.boxes[changeBoxIndex] = payload;
      // }
    },
    deleteBox: (state, { payload }: PayloadAction<BoxDeleteModel>) => {},
    deleteBoxSuccess: (state, { payload }: PayloadAction<ScheduleBoxModel>) => {
      // const deleteBoxIndex = state.kpiData?.schedules.findIndex(box => box.id === payload.id);
      // if (state.kpiData && state.kpiData.schedules && deleteBoxIndex) {
      //     state.kpiData.schedules.boxes.splice(deleteBoxIndex,1);
      // }
    },
    clearState: state => {
      localStorage.removeItem('savedSelectedFilters');
      localStorage.removeItem('savedSelectedFilterOptions');
      localStorage.removeItem('savedSelectedPCDFilters');
      localStorage.removeItem('savedSelectedPCDFilterOptions');
      localStorage.removeItem('savedSelectedUfOptions');
      localStorage.removeItem('savedSelectedMfOptions');
      localStorage.removeItem('savedSelectedGrouping');
      localStorage.removeItem('savedSelectedGroups');

      return initialState;
    },
  },
});

const {
  setKpiGraphResetZoom,
  setKpiGraphHidden,
  setZoomToCurrent,
  getKpiData,
  getKpiDataSuccess,
  getKpiNfsData,
  getKpiNfsDataSuccess,
  getFilters,
  getFiltersSuccess,
  getFiltersPCD,
  getFiltersPCDSuccess,
  setFiltersPCD,
  setFiltersPCDSuccess,
  saveSelectedGrouping,
  saveSelectedGroups,
  saveSelectedFilters,
  saveSelectedPCDFilters,
  saveSelectedPCDFilterOptions,
  saveSelectedUfOptions,
  saveSelectedMfOptions,
  getMainMf,
  getMainMfSuccess,
  getMfFromParent,
  getMfFromParentSuccess,
  getMainUf,
  getMainUfSuccess,
  getUfFromParent,
  getUfFromParentSuccess,
  searchMForUFCode,
  searchMForUFCodeSuccess,
  createSchedule,
  createScheduleSuccess,
  updateSchedule,
  updateScheduleSuccess,
  deleteSchedule,
  deleteScheduleSuccess,
  createStone,
  createStoneSuccess,
  updateStone,
  updateStoneSuccess,
  deleteStone,
  deleteStoneSuccess,
  createMarker,
  createMarkerSuccess,
  updateMarker,
  updateMarkerSuccess,
  deleteMarker,
  deleteMarkerSuccess,
  setActiveMarker,
  setToolBarActiveComponent,
  createFlowLine,
  createFlowLineSuccess,
  updateFlowLine,
  updateFlowLineSuccess,
  deleteFlowLine,
  deleteFlowLineSuccess,
  setActiveFlowLine,
  setSelectedFL,
  setSelectedSchedule,
  createBox,
  createBoxSuccess,
  updateBox,
  updateBoxSuccess,
  deleteBox,
  deleteBoxSuccess,
  clearState,
} = kpiData.actions;

export {
  setKpiGraphResetZoom,
  setKpiGraphHidden,
  setZoomToCurrent,
  getKpiData,
  getKpiDataSuccess,
  getFilters,
  getFiltersSuccess,
  getFiltersPCD,
  getFiltersPCDSuccess,
  setFiltersPCD,
  setFiltersPCDSuccess,
  saveSelectedGrouping,
  saveSelectedGroups,
  saveSelectedFilters,
  saveSelectedPCDFilters,
  saveSelectedPCDFilterOptions,
  saveSelectedUfOptions,
  saveSelectedMfOptions,
  getMainMf,
  getMainMfSuccess,
  getMfFromParent,
  getMfFromParentSuccess,
  getMainUf,
  getMainUfSuccess,
  getUfFromParent,
  getUfFromParentSuccess,
  searchMForUFCode,
  searchMForUFCodeSuccess,
  createSchedule,
  createScheduleSuccess,
  updateSchedule,
  updateScheduleSuccess,
  deleteSchedule,
  deleteScheduleSuccess,
  createStone,
  createStoneSuccess,
  updateStone,
  updateStoneSuccess,
  deleteStone,
  deleteStoneSuccess,
  createMarker,
  createMarkerSuccess,
  updateMarker,
  updateMarkerSuccess,
  deleteMarker,
  deleteMarkerSuccess,
  setActiveMarker,
  setToolBarActiveComponent,
  createFlowLine,
  createFlowLineSuccess,
  updateFlowLine,
  updateFlowLineSuccess,
  deleteFlowLine,
  deleteFlowLineSuccess,
  setActiveFlowLine,
  setSelectedFL,
  setSelectedSchedule,
  createBox,
  createBoxSuccess,
  updateBox,
  updateBoxSuccess,
  deleteBox,
  deleteBoxSuccess,
  clearState,
  getKpiNfsData,
  getKpiNfsDataSuccess,
};

export default kpiData.reducer;
