import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import {
  getClientProjects,
  getClientProjectsSuccess,
  getProject,
  getProjectSuccess,
  getClientFromProject,
  getClientFromProjectSuccess,
} from './clientProjectsSlice';
import Api from '../../services/api';
import { PayloadModel } from '../../models/global';

function* getClientProjectsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<{ clientId: number }, null> = yield take(getClientProjects.toString());

    try {
      const response = yield call(Api.get, `client/get-clients-project/${data.clientId}`);
      if (response) {
        yield put(getClientProjectsSuccess({ ...response.data, selected_client_id: +data.clientId }));
        if (callback) {
          callback(response.data);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getProjectSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<{ id: number }, null> = yield take(getProject.toString());

    try {
      const response = yield call(Api.get, `project/${data.id}`);
      if (response) {
        yield put(getProjectSuccess(response.data));
        if (callback) {
          callback(response.data);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

function* getClientFromProjectSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { data, callback },
    }: PayloadModel<{ code: string }, null> = yield take(getClientFromProject.toString());

    try {
      const response = yield call(Api.post, `client/get-client-from-project/${data.code}`);
      if (response) {
        yield put(getClientFromProjectSuccess({ client_id: +response.data.client.id }));

        if (callback) {
          callback(response.data);
        }
      }
    } catch (e: any) {
      console.log('KpiDataError', e.response);
      // if (e.response.status === 400) {
      //   callback(e.response.data.errors);
      // }
    }
  }
}

export function* saga() {
  yield all([getClientProjectsSaga(), getProjectSaga(), getClientFromProjectSaga()]);
}
