import React, { useCallback, useEffect, useMemo } from 'react';
import { usePostMessage, OnLogin, PostTypes, OnCompanySelect } from '../../../hooks/usePostMessage';

import { useDispatch } from 'react-redux';
import { getUserInfo, login } from '../../../store/authData/authoriztionSlice';
import { AUTH_SERVICE_URL } from '../../../constants/api';
import { setClient } from '../../../store/clientProjectsData/clientProjectsSlice';
import { useHistory } from 'react-router-dom';

const Auth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { subscribe } = usePostMessage();
  const onLogin = useCallback(
    (data: OnLogin.Data) => {
      dispatch(login({ token: data.token, user_id: data.user_id }));
      dispatch(getUserInfo({ id: Number(data.user_id) }));
    },
    [dispatch],
  );
  const onCompanySelect = useCallback(
    (data: OnCompanySelect.Data) => {
      dispatch(setClient(+data.selected_client_id));
    },
    [dispatch],
  );

  useEffect(() => {
    subscribe({ type: PostTypes.ON_LOGIN, callback: onLogin });
    subscribe({ type: PostTypes.ON_COMPANY_SELECT, callback: onCompanySelect });
  }, [onLogin, onCompanySelect]);

  return <iframe className="auth-iframe" src={`${AUTH_SERVICE_URL}/?onlyauth=login`}></iframe>;
};

export default Auth;
