import React from 'react';
import './Button.scss';

interface IProps {
  onClick?: (e: any) => void;
  className?: string;
  title?: string;
  type?: 'primary' | 'outline';
  size?: 'sm' | 'lr' | 'md' | 'x';
}

const Button = ({ onClick, title, className, type, size }: IProps) => {
  return (
    <button onClick={onClick} className={`button button-${type || 'primary'} button-${size || 'sm'} ${className || ''}`}>
      {title || ''}
    </button>
  );
};

export default Button;
