import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { useHistory, useParams } from 'react-router-dom';
import { getProject, setCurrentBuilding } from '../../store/clientProjectsData/clientProjectsSlice';
import { BuildingModel } from '../../models';
import { UserModel } from '../../store/authData/authoriztion.types';

export const useProject = () => {
  const history = useHistory();
  const params = useParams<any>();
  const dispatch = useDispatch();
  const userInfo: UserModel = useSelector<RootState>(state => state.authorization.userInfo) as UserModel;
  const client_id = useSelector<RootState>(state => state.clientsProjectsReducer.currentClientId);
  const fullCurrentProject = useSelector((state: RootState) => state.clientsProjectsReducer.fullCurrentProject);
  const clientProjectLoading = useSelector((state: RootState) => state.clientsProjectsReducer.clientProjectLoading);
  const currentBuilding = useSelector((state: RootState) => state.clientsProjectsReducer.currentBuilding);
  const [currentProject, setCurrentProject] = useState<null | any>(null);
  const [currentProjectId, setCurrentProjectId] = useState<number>(null);
  const [projectCode, setProjectCode] = useState<string | null>(params.projectCode);

  useEffect(() => {
    if (fullCurrentProject) {
      const buildingId = history.location.pathname.split('/')[2];
      const currentBuilding = fullCurrentProject.buildings.find(f => f.id === Number(buildingId));
      if (currentBuilding) {
        dispatch(setCurrentBuilding(currentBuilding));
      } else {
        dispatch(setCurrentBuilding(fullCurrentProject.buildings[0]));
      }
    }
  }, [fullCurrentProject]);

  useEffect(() => {
    if (userInfo && projectCode && projectCode !== ':projectCode') {
      const project = userInfo.projects.find(f => f.code === projectCode);
      setCurrentProject(project);
    }
  }, [userInfo, projectCode]);

  return {
    currentProject: fullCurrentProject,
    clientProjectLoading,
    currentProjectId,
    setCurrentProjectId,
    setProjectCode,
    currentBuilding,
  };
};
