export const MenuPropsSingle: any = {
  PaperProps: {
    style: {
      maxHeight: '419px',
      overflowY: 'auto',
      maxWidth: '400px',
      minWidth: '280px',
      backgroundColor: 'var(--background-main)',
      borderRadius: '6px',
      zIndex: 3,
    },
  },
  MenuListProps: {
    style: {
      padding: '0',
      paddingTop: '0',
      paddingBottom: '0',
      backgroundColor: 'var(--background-header)',
      '&:hover': {
        backgroundColor: 'var(--background-popupContent)',
        color: 'var(--color)',
      },
      '&:focus': {
        background: 'inherit',
      },
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export const MenuPropsSingleSelected = {
  backgroundColor: 'var(--background-main)',
  fontWeight: 'var(--font-weight)',
  color: 'var(--color)',
};

export const MenuPropsSingleValue = {
  fontWeight: 'normal',
  color: 'var(--color)',
};
