// @ts-nocheck
import { uniqBy } from 'lodash';

export function sortByDate(array) {
  const sortedItems = array.sort((a, b) => Date.parse(a.created) - Date.parse(b.created));
  return sortedItems;
}
export let uniqueUfs1 = ld => {
  const data = ld
    .map(d => d.data)
    .flat()
    .map(d => d.uf.split(',')[0].slice(0, 1));
  if (!data.length) {
    return [];
  }
  return [...(new Set(data) || [])].sort();
};

export let uniqueUfs2 = ld => {
  const data = ld
    .map(d => d.data)
    .flat()
    .map(d => d.uf.split(',')[0].slice(0, 3));
  if (!data.length) {
    return [];
  }
  return [...(new Set(data) || [])].sort();
};

export let uniqueReqCompanies = ld => {
  const data = ld
    .map(d => d.data)
    .flat()
    .map(d => d.reqCompany);
  if (!data.length) {
    return [];
  }
  return [...(new Set(data) || [])].sort();
};

export let uniqueResCompanies = ld => {
  const data = ld
    .map(d => d.data)
    .flat()
    .map(d => d.resCompany);
  if (!data.length) {
    return [];
  }
  return [...(new Set(data) || [])].sort();
};

export let uniqueReqTypes = ld => {
  const data = ld
    .map(d => d.data)
    .flat()
    .map(d => d.reqType);
  if (!data.length) {
    return [];
  }
  return [...(new Set(data) || [])].sort();
};

export let uniqueMilestones = ld => {
  const data = ld
    .map(d => d.data)
    .flat()
    .map(d => d.milestone);
  if (!data.length) {
    return [];
  }
  return [...(new Set(data) || [])].sort();
};

export let uniqueBuildingSpaces = ld => {
  const data = ld
    .map(d => d.data)
    .flat()
    .map(d => d.building_spaces.split(',')[0]);
  if (!data.length) {
    return [];
  }
  return [...(new Set(data) || [])].sort();
};

export let FilterSetting = function (option = null) {
  this.init = function (ld) {
    this.uniqUfs1 = uniqueUfs1(ld);
    this.uniqUfs2 = uniqueUfs2(ld);
    this.uniqReqComp = uniqueReqCompanies(ld);
    this.uniqResComp = uniqueResCompanies(ld);
    this.uniqReqTypes = uniqueReqTypes(ld);
    this.uniqMilestones = uniqueMilestones(ld);
    this.uniqBuildingSpaces = uniqueBuildingSpaces(ld);
  };
  // set initial state
  // default setting for nf grouping
  this.splitSetting = 'type';
  // "combine", "type", "kpi", "uf", "company"

  this.result = null;
  this.groups = null;
  this.countOpenCloseByLevel = null;
  this.countOpenClose = null;
  this.countOpenCloseByGroup = null;

  // apply filter to single dataset
  this.applyOne = function (dataset = {}) {
    // console.log("dataset", dataset)
    // console.log("groupin by", this.splitSetting)
    let result = Object.values(dataset).flat();
    // sort by date
    let values = result || [];
    // let values = dataset
    let sorted = values.length ? sortByDate(values) : [];

    let result2 = {};
    switch (this.splitSetting) {
      case 'no-group': {
        sorted.forEach(nf => {
          if (!result2['']) result2[''] = [];
          result2[''].push(nf);
        });
        break;
      }
      case 'type': {
        sorted.forEach(nf => {
          const group_name = nf.reqType || '';
          if (!result2[group_name]) result2[group_name] = [];
          result2[group_name].push(nf);
        });
        break;
      }
      case 'uf-1': {
        sorted.forEach(nf => {
          const group_name = nf.uf.split(',')[0].slice(0, 1) || '';
          if (!result2[group_name]) result2[group_name] = [];
          result2[group_name].push(nf);
        });
        // console.log(result2)
        break;
      }
      case 'uf-2': {
        sorted.forEach(nf => {
          const group_name = nf.uf.split(',')[0].slice(0, 3) || '';
          if (!result2[group_name]) result2[group_name] = [];
          result2[group_name].push(nf);
        });
        // console.log(result2)
        break;
      }
      case 'company': {
        sorted.forEach(nf => {
          const group_name = nf.reqCompany || '';
          if (!result2[group_name]) result2[group_name] = [];
          result2[group_name].push(nf);
        });
        // console.log(result2)
        break;
      }
      case 'response-company': {
        sorted.forEach(nf => {
          const group_name = nf.resCompany || '';
          if (!result2[group_name]) result2[group_name] = [];
          result2[group_name].push(nf);
        });
        // console.log(result2)
        break;
      }
      case 'nf-status': {
        sorted.forEach(nf => {
          const group_name = nf.nfStatus || '';
          if (!result2[group_name]) result2[group_name] = [];
          result2[group_name].push(nf);
        });
        // console.log(result2)
        break;
      }
      case 'milestone': {
        sorted.forEach(nf => {
          const group_name = nf.milestone || '';
          if (!result2[group_name]) result2[group_name] = [];
          result2[group_name].push(nf);
        });
        // console.log(result2)
        break;
      }
      case 'building_spaces': {
        sorted.forEach(nf => {
          const group_name = nf.building_spaces.split(',')[0];
          if (!result2[group_name]) result2[group_name] = [];
          result2[group_name].push(nf);
        });
        // console.log(result2)
        break;
      }
      // case 'deliverables': {
      //   sorted.forEach(nf => {
      //     const group_name = nf.reqType || '';
      //     if (!result2[group_name]) result2[group_name] = [];
      //     result2[group_name].push(nf);
      //   });
      //   break;
      // }
      // case "combine":{
      //   result2["combined"] = sorted
      //   // result2 = result
      //   break
      // }
      default: {
        break;
      }
    }
    // console.log("result2", result2)
    return result2;
  };

  // apply filter to leveltree - array of objects each representing items on one level
  this.applyAll = function (leveldata = []) {
    // console.log("levelData", leveldata)
    // parse data using filters
    let result = {};
    leveldata.forEach(level => {
      // console.log("level", level)
      // console.log("applied one", this.applyOne(level.data))
      result[level.index] = this.applyOne(level.data);
    });

    let arrayResult = { ...result };
    arrayResult.length = leveldata.length;

    let groups = Array.from(arrayResult).reduce((acc, levelGroups) => {
      Object.keys(levelGroups).forEach(groupName => {
        if (!acc[groupName]) {
          acc[groupName] = levelGroups[groupName];
        } else {
          acc[groupName] = uniqBy(acc[groupName].concat(levelGroups[groupName]), 'id');
        }
      });
      return acc;
    }, {});

    const countOpenCloseByLevel = leveldata.map(level => {
      const openClose = {
        id: level.id,
        index: level.index,
        name: level.name,
        opened: [],
        closed: [],
      };

      level.data.forEach(nf => {
        if (nf.nfStatus === 'accepted' || (nf.nfStatus === 'completed' && nf.is_whiteboard)) {
          openClose.closed.push(nf);
        } else {
          openClose.opened.push(nf);
        }
      });

      return openClose;
    });

    let countOpenClose =
      countOpenCloseByLevel &&
      countOpenCloseByLevel.reduce((acc, level) => {
        if (!acc['closed']) {
          acc['closed'] = level.closed;
        } else {
          acc['closed'] = acc['closed'].concat(level.closed);
        }

        if (!acc['opened']) {
          acc['opened'] = level.opened;
        } else {
          acc['opened'] = acc['opened'].concat(level.opened);
        }

        return acc;
      }, {});

    countOpenClose = {
      opened: uniqBy(countOpenClose.opened, 'id'),
      closed: uniqBy(countOpenClose.closed, 'id'),
    };

    const countOpenCloseByGroup = Object.entries(result).reduce((acc, level) => {
      Object.keys(level[1]).forEach(group => {
        const groupOpenClosed = level[1][group].reduce((accGroup, nf) => {
          if (!accGroup['closed']) {
            accGroup['closed'] = [];
          }
          if (!accGroup['opened']) {
            accGroup['opened'] = [];
          }

          if (nf.nfStatus === 'accepted' || (nf.nfStatus === 'completed' && nf.is_whiteboard)) {
            accGroup['closed'].push(nf);
          } else {
            accGroup['opened'].push(nf);
          }
          return accGroup;
        }, {});

        if (!acc[level[0]]) {
          acc[level[0]] = {};
        }
        acc[level[0]][group] = groupOpenClosed;
      });

      return acc;
    }, {});

    // console.log('countOpenCloseByLevel', countOpenCloseByLevel)
    this.countOpenCloseByLevel = countOpenCloseByLevel;
    // console.log('countOpenClose', countOpenClose)
    this.countOpenClose = countOpenClose;
    // console.log('countOpenCloseByGroup',countOpenCloseByGroup)
    this.countOpenCloseByGroup = countOpenCloseByGroup;
    // console.log('groups',groups)
    this.groups = groups;
    // console.log("result", result)
    this.result = result;
    // console.log(result)
    return result;
  };

  this.applyAll();
};

let filterSetting = new FilterSetting();

function create_UUID() {
  // var dt = new Date().getTime();
  // var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  //     var r = (dt + Math.random()*16)%16 | 0;
  //     dt = Math.floor(dt/16);
  //     return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  // });
  // return uuid;
  return new Date().getTime();
}

export { filterSetting, create_UUID };
