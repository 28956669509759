import { filterSetting } from '../components/common/Kpi/utils/utils';
import { colorType } from '../components/common/Kpi/settings/colors';

export const getColor = group => {
  let groupName;
  const fadedPosition = group.indexOf('-faded');

  if (fadedPosition !== -1) {
    groupName = group.slice(0, fadedPosition);
  } else {
    groupName = group;
  }

  switch (filterSetting.splitSetting) {
    case 'combine':
      return colorType['combine']['all'];
    case 'type':
      return colorType['type'][groupName];
    case 'kpi':
      return colorType['kpi'][groupName];
    case 'uf-1':
      return colorType['uf'][groupName];
    case 'uf-2':
      return colorType['uf'][groupName];
    case 'company':
      if (group === '') {
        return '#AAAAAA';
      } else {
        return colorType['default'][filterSetting.uniqReqComp?.indexOf(groupName) % 14];
      }
    case 'response-company':
      if (group === '') {
        return '#AAAAAA';
      } else {
        return colorType['default'][filterSetting.uniqResComp?.indexOf(groupName) % 14];
      }
    case 'nf-status':
      return colorType['nf-status'][groupName];
    case 'milestone':
      if (groupName === '') {
        return '#AAAAAA';
      } else {
        return colorType['default'][filterSetting.uniqMilestones?.indexOf(groupName) % 14];
      }
    case 'building_spaces':
      if (groupName === '') {
        return '#AAAAAA';
      } else {
        return colorType['default'][filterSetting.uniqBuildingSpaces?.indexOf(groupName) % 14];
      }
    case 'no-group':
      return colorType['no-group'][groupName];
    default:
      break;
  }
};
