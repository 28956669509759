export const roles = {
  ADMIN: '1',
  USER: '2',
  COMPANY_MANEGER: '3',
  SUPER_ADMIN: '4',
  WORKFLOW_MANEGER: '5',
};

export default class AccessHelper {
  static isProfileAccessCheck = (userData, user) => {
    if (Object.keys(userData?.roles || {}).filter(key => key === '2').length) {
      return false;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '1' || key === '4').length) {
      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '3').length) {
      if (user.roles[0].id === 2 || user.roles[0].id === 3) {
        return true;
      } else {
        return false;
      }
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '5').length) {
      if (user.roles[0].id === 2 || user.roles[0].id === 3 || user.roles[0].id === 5) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  static isAccessCheck = userData => {
    //false - ban
    if (!userData?.roles) {
      return false;
    }
    if (Object.keys(userData.roles).filter(key => key === roles.USER || key === roles.COMPANY_MANEGER).length) {
      return false;
    }

    return true;
  };
  // is exist user in this company
  static isSelfCompany = (userData, company) => {
    if (Object.keys(userData?.roles || {}).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
    if (Object.keys(userData?.roles || {}).filter(key => key === '3').length) {
      const findUser = company?.users?.find(user => user.id === userData.id);
      if (findUser) {
        return true;
      } else {
        return false;
      }
    }
  };

  static isAccessToRequestEdit = (userData, request, projectDisciplines) => {
    if (!userData?.role || !projectDisciplines) {
      return false;
    }
    if (Object.keys(userData.role).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
    if (!request) {
      return true;
    }
    /*
    if (Object.keys(userData.role).filter(key => key === '2').length) {
      if (request && request.created_by_user_id === userData.id) {
        return true;
      } else {
        return false;
      }
    }
    */
    if (Object.keys(userData?.role || {}).filter(key => key === '3' || key === '2').length) {
      // const userExistInParty = AccessHelper.userExistInParty(userData, projectParties);
      const userExistInParty = AccessHelper.userExistInParty(userData, projectDisciplines);
      if (userExistInParty?.find(party => party?.id === request?.request_party_id)?.discipline_id === request?.req_disc_id) {
        return true;
      } else {
        return false;
      }
    }
  };

  static isAccessToCommitmentEdit = (userData, request, projectDisciplines) => {
    if (!userData?.role || !projectDisciplines || (request && request.is_whiteboard === 0)) {
      return false;
    }
    if (Object.keys(userData.role).filter(key => key === '1' || key === '4' || key === '5').length) {
      return true;
    }
    if (Object.keys(userData.role).filter(key => key === '2').length) {
      const userExistInParty = AccessHelper.userExistInParty(userData, projectDisciplines);
      if (
        userExistInParty?.find(party => party?.id === request?.response_party_id)?.discipline_id === request?.resp_disc_id ||
        (request?.commitment && Number(request?.commitment?.user_id) === userData.id)
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (Object.keys(userData?.role || {}).filter(key => key === '3').length) {
      const userExistInParty = AccessHelper.userExistInParty(userData, projectDisciplines);
      if (userExistInParty?.find(party => party?.id === request?.response_party_id)?.discipline_id === request?.resp_disc_id) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  // Collect parties where users exist
  static userExistInParty = (userData, projectDisciplines) => {
    const userExistInParties = [];
    projectDisciplines.map(discipline => {
      discipline.parties.map(party => {
        const isUser = party.users?.find(user => user.id === userData.id);
        if (isUser) {
          if (party.party) {
            userExistInParties.push(party.party);
          } else {
            party.discipline_id = discipline.id;
            userExistInParties.push(party);
          }
        }
      });
    });

    return userExistInParties;
  };

  static isAccessToRemoveRequest = (userData, request, projectDisciplines) => {
    if (!(userData?.role || userData?.roles) || !projectDisciplines) {
      return false;
    }
    if (Object.keys(userData?.role || userData?.roles).filter(key => key === '1' || key === '4').length) {
      return true;
    }
    if (request.commitment) {
      return false;
    }
    /*if (Object.keys(userData?.role || userData?.roles).filter(key => key === '2').length) {
      if (request && request.created_by_user_id === userData.id && !request.is_whiteboard) {
        return true;
      } else {
        return false;
      }
    }*/

    if (Object.keys(userData?.role || userData?.roles).filter(key => key === '2' || key === '3' || key === '5').length) {
      const userExistInParty = AccessHelper.userExistInParty(userData, projectDisciplines);
      if (
        !request?.is_whiteboard &&
        userExistInParty?.find(party => party?.id === request?.request_party_id)?.discipline_id === request?.req_disc_id
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  static isAccessToShareFilters = userData => {
    if (Object.keys(userData?.role || userData?.roles).filter(key => key === '1' || key === '4').length) {
      return true;
    }
  };
}
