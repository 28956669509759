import { ISelectOptions } from '../models/global';
import { initialAdvancedDisplaySettings, initialDisplaySettings } from '../components/common/toolbar/settingsPanel/settingsPanel';
import { AdvancedDisplaySettingsModel, DisplaySettingsModel } from '../components/common/toolbar/settingsPanel/settingsPanel.types';
import { KeyValueModel } from '../models';

export interface ICustomFields {
  [custom: string]: string;
}

export interface Validator {
  required?: Array<string>;
  custom?: any;
  requiredFieldName?: KeyValueModel<string>;
}

export const validate = <T = KeyValueModel<string>>(validator: Validator, fields: T) => {
  if (validator) {
    let errors: KeyValueModel<string> = {};
    let unfilled =
      validator &&
      validator.required &&
      validator.required.filter(
        (field: string) => !(fields[field] || fields[field] === 0) || (Array.isArray(fields[field]) && !fields[field].length),
      );
    if (validator.custom) {
      const result = validator.custom.map(({ error, validate, fields: validationFields }: any) => {
        const valid = validate(...validationFields.map((field: string) => fields[field]));
        const errors: KeyValueModel<string> = {};
        validationFields.forEach((item: string) => {
          errors[item] = error;
        });
        return valid ? null : errors;
      });
      const customErrors = result.filter(Boolean);
      if (customErrors.length) {
        customErrors.forEach((error: string) => {
          errors = Object.assign(errors, error);
        });
      }
    }
    if (unfilled.length) {
      unfilled.forEach((field: string) => {
        errors[field] = `${validator.requiredFieldName?.[field] || field.charAt(0).toUpperCase() + field.slice(1)} required`;
      });
    }
    return { errors };
  }
  return { errors: null };
};

export const email = (fields: Array<string>) => ({
  validate: validateEmail,
  fields,
});

export const phone = (fields: Array<string>) => ({
  error: 'Phone format must be XXX-XXX-XXXX',
  validate: validatePhone,
  fields,
});

export const ssn = (fields: Array<string>) => ({
  error: 'SSN format must be XXX-XX-XXXX',
  validate: validateSSN,
  fields,
});

export const samePasswords = (fields: Array<string>) => ({
  validate: (password: string, repeat: string) => password === repeat,
  fields,
});

export const httpUrl = (fields: Array<string>) => {
  return {
    validate: validateURL,
    fields,
  };
};

export const password = (fields: Array<string>) => ({
  validate: validatePassword,
  fields,
});

export const activityTime = (start: Date | null, end: Date | null, fields: Array<string>) => ({
  validate: () => validateActivityTime(start, end),
  fields,
});

export const atLeastOneOption = (fields: Array<string>) => ({
  error: 'Select at least one option',
  validate: (options: ISelectOptions[]) => !!options.length,
  fields,
});

const validatePassword = (password: string) => {
  const re = /^.*.{8,20}$/;
  return re.test(password);
};

export const validateURL = (url: string) => {
  if (!url) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return re.test(url);
};

const validateEmail = (email: string) => {
  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validatePhone = (phone: string) => {
  /* eslint-disable no-useless-escape */
  const re = /^\d{3}-\d{3}-\d{4}$/;
  return re.test(phone);
};

const validateSSN = (ssn: string) => {
  /* eslint-disable no-useless-escape */
  const re = /^\d{3}-\d{2}-\d{4}$/;
  return re.test(ssn);
};

export const checkEmptyField = (field: string) => {
  /* eslint-disable no-useless-escape */
  const re = /^\s*\d*\.?\d+\s*$/;
  return re.test(field);
};

const validateActivityTime = (start_time: Date | null, end_time: Date | null) => {
  return !(start_time === null && end_time !== null);
};

export const checkKpiDisplayOptionsFields = kpiDisplayOptions => {
  const { display_settings, advanced_display_settings } = kpiDisplayOptions;
  const displaySettingsKeys: string[] = Object.keys(initialDisplaySettings);
  const advancedDisplaySettingsKeys: string[] = Object.keys(initialAdvancedDisplaySettings);

  let newDisplaySetting: DisplaySettingsModel = { ...display_settings };
  let newAdvancedDisplaySettings: AdvancedDisplaySettingsModel = { ...advanced_display_settings };

  if (!display_settings) {
    newDisplaySetting = { ...initialDisplaySettings };
  } else {
    displaySettingsKeys.forEach(key => {
      if (typeof newDisplaySetting[key] === 'undefined') {
        newDisplaySetting[key] = initialDisplaySettings[key];
      }
    });
  }

  if (!advanced_display_settings) {
    newAdvancedDisplaySettings = { ...initialAdvancedDisplaySettings };
  } else {
    advancedDisplaySettingsKeys.forEach(key => {
      if (typeof newAdvancedDisplaySettings[key] === 'undefined') {
        newAdvancedDisplaySettings[key] = initialAdvancedDisplaySettings[key];
      }
    });
  }

  return {
    display_settings: newDisplaySetting,
    advanced_display_settings: newAdvancedDisplaySettings,
  };
};
