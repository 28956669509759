import { filterSetting } from '../components/common/Kpi/utils/utils';
import { StatusNameHelper } from './StatusNameHelper';

export const getGroupingType = () => {
  if (filterSetting.splitSetting === 'type') {
    return 'type';
  }
  if (filterSetting.splitSetting === 'uf-1') {
    return 'uniformat L1';
  }
  if (filterSetting.splitSetting === 'uf-2') {
    return 'uniformat L2';
  }
  if (filterSetting.splitSetting === 'company') {
    return 'request company';
  }
  if (filterSetting.splitSetting === 'response-company') {
    return 'response company';
  }
  if (filterSetting.splitSetting === 'nf-status') {
    return 'nf status';
  }
  if (filterSetting.splitSetting === 'milestone') {
    return 'milestone';
  }
  if (filterSetting.splitSetting === 'building_spaces') {
    return 'building spaces';
  }
  if (filterSetting.splitSetting === 'no-group') {
    return '';
  }
  return '';
};

export const getGroupTitle = title => {
  if (title) {
    if (filterSetting.splitSetting === 'nf-status') {
      return StatusNameHelper.getLastUserStatusName(title);
    } else {
      return title;
    }
  } else {
    if (filterSetting.splitSetting === 'type') {
      return 'No type';
    }
    if (filterSetting.splitSetting === 'uf-1') {
      return 'No UF';
    }
    if (filterSetting.splitSetting === 'uf-2') {
      return 'No UF';
    }
    if (filterSetting.splitSetting === 'company' || filterSetting.splitSetting === 'response-company') {
      return 'No company';
    }
    if (filterSetting.splitSetting === 'nf-status') {
      return 'No status';
    }
    if (filterSetting.splitSetting === 'milestone') {
      return 'No milestone';
    }
    if (filterSetting.splitSetting === 'building_spaces') {
      return 'No space';
    }
    if (filterSetting.splitSetting === 'no-group') {
      return 'NF';
    }
  }
};
