import React, { useCallback, useEffect, useMemo } from 'react';
import { usePostMessage, OnCompanySelect, PostTypes } from '../../../hooks/usePostMessage';

import { useDispatch } from 'react-redux';
import { setClient } from '../../../store/clientProjectsData/clientProjectsSlice';
import { AUTH_SERVICE_URL } from '../../../constants/api';
import { useHistory } from 'react-router-dom';
import { ROOTS } from '../../../routes/routes';

const CompanyChooser = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { subscribe } = usePostMessage();

  const onSelectCompany = useCallback(
    (data: OnCompanySelect.Data) => {
      dispatch(setClient(data.selected_client_id));
      history.push('/graph');
      // history.push(ROOTS.WELCOME);
    },
    [dispatch],
  );

  useEffect(() => {
    subscribe({ type: PostTypes.ON_COMPANY_SELECT, callback: onSelectCompany });
  }, [onSelectCompany]);

  return <iframe className="auth-iframe" src={`${AUTH_SERVICE_URL}/?kpiframe=company`}></iframe>;
};

export default CompanyChooser;
