export class StatusNameHelper {
  static getLastUserStatusName = (status, is_whiteboard?) => {
    // const status = request?.lastUserStatus?.status ? request?.lastUserStatus?.status : request?.lastStatus;
    if (status === 'drafted') {
      return 'Request Drafted';
    } else if (status === 'sent') {
      return 'Request Sent';
    } else if (status === 'submitted') {
      return 'Commitment Submitted';
    } else if (status === 'provided') {
      return 'Deliverable Provided';
    } else if (status === 'declined') {
      return 'Deliverable Declined';
    } else if (status === 'accepted' || (status === 'completed' && is_whiteboard)) {
      return 'Deliverable Accepted';
    } else {
      return 'Status Missing';
    }
  };
}
