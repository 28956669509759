export const defaultDisplaySettings = {
  markers: true,
  schedule: true,
  tooltips: true,
  flowlines: true,
  legends: window.innerWidth >= 1201,
  addMarkers: true,
  endpoints: true,
  duration: true,
  paths: true,
  points: true,
  cursorLine: false,
  privateMarkers: true,
  statistics: window.innerWidth >= 1201,
  deliverables: true,
  deliverableLinked: true,
  declinePoints: true,
};

export const defaultAdvancedSettings = {
  level_height: 120,
  flowline_style: 'dashed',
  multi_level_type: 'show-faded',
  endpoints_width: 3,
  duration_width: 1,
  paths_width: 2,
  points_radius: 3,
  decline_radius: 3,
};

export const defaultSettings = {
  display_settings: defaultDisplaySettings,
  advanced_display_settings: defaultAdvancedSettings,
};
