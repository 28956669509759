import React, { useEffect, useState, useMemo, Suspense } from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROOTS } from './routes';
import { Home, Auth, CompanyChooser, PlugView } from '../components/pages';
import { RootState } from '../store/reducers';
import { getUserInfo } from '../store/authData/authoriztionSlice';
import { getClientProjects, getProject, getClientFromProject } from '../store/clientProjectsData/clientProjectsSlice';
import { useProject } from '../hooks';
import '../components/pages/Auth/PlugViewStyles.scss';
import { UserModel } from '../store/authData/authoriztion.types';

export const Routes = () => {
  const dispatch = useDispatch();
  const params = useParams<any>();
  const token = useSelector<RootState>(state => state.authorization.token);
  const client_id = useSelector<RootState>(state => state.clientsProjectsReducer.currentClientId);
  const user_id = useSelector<RootState>(state => state.authorization.user_id);
  const userInfo: UserModel = useSelector<RootState>(state => state.authorization.userInfo) as UserModel;
  const currentBuilding = useSelector((state: RootState) => state.clientsProjectsReducer.currentBuilding);
  const history = useHistory();
  const [isAuthenticate, setIsAuthenticate] = useState(token && user_id);
  const { currentProject, setCurrentProjectId } = useProject();
  const [projectCode, setProjectCode] = useState<string | null>(params.projectCode);
  const userIPv4FromStore = useSelector((state: RootState) => state.authorization.userIPv4);
  const [userIP, setUserIP] = useState<string | null>(null);

  useEffect(() => {
    if (userInfo && projectCode && projectCode !== ':projectCode') {
      const project = userInfo.projects.find(f => f.code === projectCode);
      dispatch(
        getProject({
          data: {
            id: project ? project.id : userInfo.projects[0].id,
          },
        }),
      );
    }
  }, [userInfo?.id, projectCode]);

  const getMainRouteContents = () => {
    const code = history.location.pathname.split('/')[1];

    if (isAuthenticate && token) {
      if (!client_id && !code) {
        return <Redirect to={ROOTS.COMPANY_CHOOSER} />;
      } else {
        if (code && !client_id) {
          dispatch(getClientFromProject({ data: { code } }));
        }

        return <Auth />;
      }
    } else {
      return <Auth />;
    }
  };
  const mainRouteContents = useMemo(() => {
    return getMainRouteContents();
  }, [isAuthenticate, token, client_id]);

  useEffect(() => {
    if (isAuthenticate && !userInfo && user_id) {
      // debugger;
      dispatch(getUserInfo({ id: +user_id }));
    }
    if (userInfo && client_id) {
      const code = history.location.pathname.split('/')[1];
      const project = userInfo.projects.find(f => f.code === code);
      dispatch(
        getClientProjects({
          data: {
            clientId: Number(project?.client_id || client_id),
          },
          callback: (res: any) => {
            const code = history.location.pathname.split('/')[1];
            const project = userInfo.projects.find(f => f.code === code);
            dispatch(
              getProject({
                data: {
                  id: project ? project.id : res.projects[0].id,
                },
              }),
            );
          },
        }),
      );
    }
  }, [userInfo?.id, client_id, user_id]);

  useEffect(() => {
    if (currentProject && currentBuilding) {
      history.push(ROOTS.WELCOME.replace(':projectCode', currentProject.code).replace(':buildingId', `${currentBuilding.id}`));
    }
  }, [currentProject, currentBuilding, user_id]);

  useEffect(() => {
    if (!token) {
      setIsAuthenticate(false);
      setProjectCode('');
      history.push(ROOTS.AUTH);
    } else if (token && user_id) {
      setIsAuthenticate(true);
    }
  }, [token]);

  useEffect(() => {
    const code = history.location.pathname.split('/')[1];
    if (!code && userInfo && client_id) {
      const currentProject = userInfo.projects.find(f => f.code === code && f.client_id === client_id);
      if (currentProject) {
        setProjectCode(code);
        dispatch(
          getProject({
            data: {
              id: currentProject.id,
            },
          }),
        );
      } else {
        const projectsByFilter = userInfo.projects.filter(f => f.client_id === client_id);
        if (projectsByFilter) {
          setProjectCode(projectsByFilter[0].code);
          dispatch(
            getProject({
              data: {
                id: projectsByFilter[0].id,
              },
            }),
          );
        }
      }
    }
  }, [projectCode, userInfo, client_id]);

  // PLUG start
  // useEffect(() => {
  //   if (userIPv4FromStore && !userIP) {
  //     setUserIP(userIPv4FromStore);
  //   }
  // }, [userIPv4FromStore]);
  // console.log('userIP>>> ', userIP);
  //
  // if (
  //   // Vpn Ideus
  //   userIP &&
  //   userIP !== '174.138.41.170' &&
  //   userIP !== '188.230.104.221' &&
  //   userIP !== '176.36.253.251' &&
  //   userIP !== '159.69.178.6' &&
  //   userIP !== '93.178.254.10' &&
  //   userIP !== '89.231.34.178' &&
  //   userIP !== '84.140.116.14' &&
  //   userIP !== '99.43.254.198'
  // ) {
  //   return (
  //     <div className="auth-iframe">
  //       <div className="b-routerContainer">
  //         <PlugView />
  //       </div>
  //     </div>
  //   );
  // }
  // PLUG end

  return (
    <Switch>
      <>
        {!userInfo && <Route path="/">{mainRouteContents}</Route>}
        <Route path={ROOTS.WELCOME} component={Home} />
        <Route path={ROOTS.COMPANY_CHOOSER} component={CompanyChooser} />
      </>
    </Switch>
  );
};
