import React from 'react';
import './LoaderStyles.scss';
import { Circles } from 'react-loader-spinner';

class LoaderView extends React.Component {
  render() {
    return (
      <div className="loader">
        <Circles color="#00BFFF" height="100" width="100" />
      </div>
    );
  }
}

export default LoaderView;
